import { Button } from '@mui/material';
import mainImg from '../../assets/homeImage.jpeg';
import stickyImg from '../../assets/shutterstock_1788685808-8f5cd4f2f49765bdffe79cc55368b237-372680.jpg';
import controlImg from '../../assets/shutterstock_1070766065-681335b8bf913b18f93eb9f34dae06e1-77f25f.jpg';
import mascot from '../../assets/mascot.png'
import { FcCheckmark } from "react-icons/fc";
// import {BsFillCloudArrowUpFill} from 'react-icons/bs'
// import {RiSurveyFill} from 'react-icons/ri'
// import {FaUmbrellaBeach} from 'react-icons/fa'
import { ReactComponent as SurveyIcon } from '../../assets/noun-web-survey-3892835.svg'
import { ReactComponent as UploadIcon } from '../../assets/noun_Upload_1832612.svg'
import { ReactComponent as RelaxIcon } from '../../assets/noun_relax_1744917.svg'

import './Home.css';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import OAuthDialogList from '../firebase/OAuthDialogList';

export function Home() {
    const history = useHistory();
    const [signupOpen, setOpenSignup] = useState(false);

    const closeSignupDialogBox = () => {
        setOpenSignup(false);
    };

    const openSignupDialogBox = () => {
        history.push('/newAccount');
    };

    const handlePricing = () => {
        history.push('/pricing');
    };

    // #region Mobile Detection
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    // #endregion

    useEffect(() => {
        // user's first time! send them to createEvent
        if (window.firstTime === true) {
            history.push('/createEvent');
        }
        // otherwise to their dashboard if they are at the home screen
        else if (window.firstTime === false) {
            history.push('/userHome');
        }
    }, [window.firstTime]);

    return (
        <>
            <section className='eyeCatcher'>
                <section className='gradientCover'>
                    <section className='introSection'>
                        <h1 className='homeTitle'>Seating arrangements made easy</h1>
                        {isMobile ? <img id='mainImage' src={mainImg} /> : <></>}

                        <p>Send a seating preference survey to guests and be amazed as Placecard transforms their responses into your seating chart.</p>
                        <section className='tryPlacecardButtonGroup'>
                            <Button variant='contained' onClick={openSignupDialogBox} color='info'>Try it free</Button>
                            <small>No credit card required.</small>
                        </section>
                    </section>
                </section>
                {isMobile ? <></> : <img id='mainImage' src={mainImg} />}
            </section>
            <section className='mainContent'>
                <section className='centeredContent introduction'>
                    <img className='descriptionImg' alt="sticky notes" src={stickyImg} />
                    <section className='description'>
                        <h2 className='smallTitle'>Ditch the sticky notes and spreadsheets</h2>
                        <p>
                            Harness the power of crowdsourcing with our unique seating preference survey that asks guests 3 simple questions:
                        </p>
                        <ol>
                            <li>Who do you feel <em>comfortable</em> sitting with?</li>
                            <li>Who do you <em>ideally</em> want to sit with?</li>
                            <li>Who do you <em>not</em> want to sit with?</li>
                        </ol>
                        <p>
                            Witness the magic as Placecard generates a customized seating chart, powered by your guests' responses.
                        </p>
                        <section className='tryPlacecardButtonGroup'>

                            <Button variant='contained' onClick={openSignupDialogBox} color='info'>Try it free</Button>
                            <small>No credit card required.</small>
                        </section>
                    </section>

                </section>
                <section className='centeredContent control'>
                    <section className='description'>
                        <h2 className='smallTitle'>Stay in control</h2>
                        <p>
                            Our user-friendly dashboard allows you to move guests around, make manual edits, and ensure every seat is perfect. Your preferences matter, and Placecard gives you the final say.
                        </p>
                        <section className='tryPlacecardButtonGroup'>
                            <Button variant='contained' onClick={openSignupDialogBox} color='info'>Try it free</Button>
                            <small>No credit card required.</small>
                        </section>
                    </section>
                    <img src={controlImg} alt='a couple in control' className='descriptionImg' />
                </section>
                <section className='pricingInfo'>
                    <h2 className='smallTitle'>Effortless seating arrangements for free</h2>
                    <section className='card'>
                        <section>
                            <p className='price'>$0</p>
                            <section className='tryPlacecardButtonGroup'>

                                <Button variant='contained' onClick={openSignupDialogBox} color='info'>Get started</Button>
                                <small>No credit card required.</small>
                            </section>
                        </section>
                        <section className='checkList'>
                            <section>
                                <FcCheckmark />
                                <p>Up to 10 events</p>
                            </section>
                            <section>
                                <FcCheckmark />
                                <p>Up to 1,000 guests per event</p>
                            </section>
                            <section>
                                <FcCheckmark />
                                <p>Seating preference survey</p>
                            </section>
                            <section>
                                <FcCheckmark />
                                <p>Make manual edits</p>
                            </section>
                            <section>
                                <FcCheckmark />
                                <p>Send guest survey reminders</p>
                            </section>
                        </section>

                    </section>
                    <p className='disclaimer'>We may occasionally reach out to gather your valuable feedback.</p>
                </section>
                <section className='missionStatement'>
                    <p>At Placecard, we’re on a mission to build innovative tools to help make the event planning process a little less stressful and a lot more fun.</p>
                    <img src={mascot} alt='placecard icon'></img>
                </section>
            </section>
            <OAuthDialogList
                open={signupOpen}
                onClose={closeSignupDialogBox}
                title='Sign up for'
                setOpen={setOpenSignup}
            />
        </>
    )
}