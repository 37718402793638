import { Button, Card, CardActions, CardContent, CardHeader, IconButton, TextareaAutosize } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { FullSurvey } from '../guestPages/FullSurvey';
import './editPages.css';
import { Modal } from '../shared/Modal';
import { useEffect, useState } from 'react';
import { BreakfastDiningOutlined } from '@mui/icons-material';

export function EditSurvey () {
    window.setGuestMode(false);
    const history = useHistory();
    const [isDisabled, setDisabled] = React.useState(false);
    const defaultMainText = "I'm using the Placecard App to create a seating chart for my upcoming event.\n\nCan you please take a quick, three-question seating preference survey?\n\nPlacecard will utilize the survey responses to create optimal seating arrangements for all our guests.";

    const defaultSignatureText = 'Thanks! \r\n'+window.firstNameState + ' ' + window.lastNameState;

    const updateLastSent = async (id: any) => {
        const requestOps = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        };

        let update = null;
        //change to proper backend urls 
        let guest = await fetch(process.env.REACT_APP_BACKEND_URL + '/guests/' +id, requestOps)
            .then((response) => response.json())
            .then((data) => {
                data["last_sent"]= String(new Date());
                const requestOptions = {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                };
                update = fetch(process.env.REACT_APP_BACKEND_URL + '/guests/updateGuest', requestOptions)
                    .then((response) => response)
                    .then((data) => {
                        if (data.status!=200){
                            return "Last_sent did not update"
                        }
                    });
                if (window && window.activeEvent && window.activeEvent.guestList) {
                    for (let guest of window.activeEvent.guestList){
                        if (guest.id == data._id){
                            window.activeEvent.guestList = window.activeEvent.guestList.filter(x=> x!=guest)
                            let updatedUserData = {
                                contact: data.email,
                                groupId: undefined,
                                groupName: undefined,
                                groupSize: data.party_size,
                                id: data._id,
                                last_sent: data.last_sent,
                                name: data.first_name + " " + data.last_name
                            }
                            window.activeEvent.guestList.push(updatedUserData);
                            break;
                        }
                    }
                }
        });
        return update;
    }

    const updateEventSurveys = async (id: any, surveys: any) => {
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                surveys_sent: surveys,
            })
        };
        fetch(process.env.REACT_APP_BACKEND_URL + '/events/updateEvent/'+id, requestOptions);
    }

    function deduplicate(arr:any[]){
        let temp:any[] = [];
        for (let i of arr){
            if (!temp.includes(i)){
                temp.push(i)
            }
        }
        return temp;
    }

    const sendSurvey = async () => {
        if (window.activeEvent != null) {
            const main = document.getElementById('main_survey_message');
            const closing = document.getElementById('closing_survey_message');
            let main_message = defaultMainText;
            let closing_message = defaultSignatureText;
            if (main != null && (main as HTMLInputElement).value.trim() != '') {
                main_message = (main as HTMLInputElement).value.trim();
            }
            if (closing != null && (closing as HTMLInputElement).value.trim() != '') {
                closing_message = (closing as HTMLInputElement).value.trim();
            }
            try {
                const x = document.getElementById('errorSendingAll');
                if (x !== null) {
                    x.style.display = 'none';
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }
                const y = document.getElementById('errorSendingTest');
                if (y !== null) {
                    y.style.display = 'none';
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }
                const res = await emailSurveys(main_message, closing_message);
                if (res && res.ok) {
                    await updateSentSurveys();
                    if(window.activeEvent.toSend) {
                        if(window.activeEvent.surveys){
                            window.activeEvent.surveys =  [...window.activeEvent.surveys, ...window.activeEvent.toSend];
                        }
                        else {
                            window.activeEvent.surveys =  [...window.activeEvent.toSend];
                        }

                        //get rid of duplicates here, if not the number of surveys is too high
                        window.activeEvent.surveys = deduplicate(window.activeEvent.surveys);
                        
                        window.setActiveEvent(window.activeEvent)
                        await updateRes();

                    }
                    window.activeEvent.reminders = window.activeEvent.remindersToSend;
                    //change the last sent for each user who was sent an survey/reminder
                    if (window.activeEvent.toSend){
                        for (let user of window.activeEvent.toSend) {
                            await updateLastSent(user);
                        }
                    }
                    if (window.activeEvent.remindersToSend){
                        for (let user of window.activeEvent.remindersToSend) {
                            await updateLastSent(user);
                        }
                    }
                    if(window.activeEvent.remindersToSend){
                        window.activeEvent.remindersToSend = [];
                    }
                    if(window.activeEvent.toSend){
                       window.activeEvent.toSend = [];
                    }
                    history.push('/sentConf');
                }
                else {
                    throw 'error';
                }
            } catch {
                const x = document.getElementById('errorSendingAll');
                if (x !== null) {
                    x.style.display = 'inline-block';
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }
                const y = document.getElementById('errorSendingTest');
                if (y !== null) {
                    y.style.display = 'none';
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }
            }
        }
    };

    useEffect(() => {
        if (window && window.eventsState && window.activeEvent){
            let updatedEvents = window.eventsState
            for (let i in updatedEvents){
                if (updatedEvents[i].id == window.activeEvent.id){
                    updatedEvents[i] = window.activeEvent
                    break;
                }
            }
            window.setEvents([...updatedEvents]);
        }
    }, [window.activeEvent])

    const updateRes = async () => {
        if (window.activeEvent && window.eventsState){
            await updateEventSurveys(window.activeEvent.id, window.eventsState[0].surveys);
        }
    }

    const sendSelfSurvey = async () => {
        if (window.activeEvent != null) {
            const main = document.getElementById('main_survey_message');
            const closing = document.getElementById('closing_survey_message');
            let main_message = defaultMainText;
            let closing_message = defaultSignatureText;
            if (main != null && (main as HTMLInputElement).value.trim() != '') {
                main_message = (main as HTMLInputElement).value.trim();
            }
            if (closing != null && (closing as HTMLInputElement).value.trim() != '') {
                closing_message = (closing as HTMLInputElement).value.trim();
            }
            try {
                const x = document.getElementById('errorSendingAll');
                if (x !== null) {
                    x.style.display = 'none';
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }
                const y = document.getElementById('errorSendingTest');
                if (y !== null) {
                    y.style.display = 'none';
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }
                const res = await emailTestSurvey(main_message, closing_message);
                if (res && res.ok) {
                    setDisabled(true);
                    const notification = document.getElementById('sentNotification');
                    if (notification !== null) {
                        notification.style.display = 'inline-block';
                        notification.classList.remove('disappearingClass');
                        window.requestAnimationFrame(function() {
                            notification.classList.add('disappearingClass');
                        });
                        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    }
                } else {
                    throw 'error';
                }
            } catch {
                const x = document.getElementById('errorSendingAll');
                if (x !== null) {
                    x.style.display = 'none';
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }
                const y = document.getElementById('errorSendingTest');
                if (y !== null) {
                    y.style.display = 'inline-block';
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }
            }
        }
    };

    const previewSurvey = () => {
        const el = document.getElementById('surveyPreview');
        if (el !== null) {
            el.style.display = 'block';
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
    };

    const dontClose = (event: any) => {
        event.stopPropagation();
    };

    const closePreview = () => {
        const el = document.getElementById('surveyPreview');
        if (el !== null) {
            el.style.display = 'none';
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
    }

    const showWarning = () => {
        const x = document.getElementById('hiddenWarning');
        if (x !== null) {
            x.style.display = 'inline-block';
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
    };

    const hideWarning = () => {
        const x = document.getElementById('hiddenWarning');
        if (x !== null) {
            x.style.display = 'none';
        }
    }

    return (
        <section className='editSurvey centered'>
            <section className='hiddenBoxes' id='surveyPreview' onClick={closePreview}>
                <Card className='surveyPreview' onClick={dontClose}>
                    <CardHeader className='innerBoxHeader' action={<IconButton onClick={closePreview}><AiFillCloseCircle className='closeBtn'/></IconButton>} title='Preview Survey'/>
                        <CardContent>
                            <FullSurvey preview={true} hostView={true}></FullSurvey>
                        </CardContent>
                        <CardActions className='previewFooter'>
                            <Button color='info' variant='contained' className='basicBtn fitBtn' onClick={closePreview}>Close Preview</Button>
                        </CardActions>
                </Card>
            </section>
            <h1 className='title'>Customize Invitation</h1>
            <p className='subtitle hiddenNote' id='sentNotification'>Test Email Sent!<br/>(Note: Your email may take up to 24 hours to deliver)</p>
            <p className='subtitle pageError' id='errorSendingAll'>There was a problem sending your survey invites. Please try again later. If problems persist, please contact us at <a className='textLink' href='mailto:help@placecard.com'>help@placecard.com</a></p>
            <p className='subtitle pageError' id='errorSendingTest'>There was a problem sending the email preview. Please try again later. If problems persist, please contact us at <a className='textLink' href='mailto:help@placecard.com'>help@placecard.com</a></p>
            <p className='subtitle'>Great! Now it's time to customize your survey invitation!</p>
            <Card className='box'>
                <p className='details'>Hello [Primary Group Contact]!</p>
                <TextareaAutosize className='customInput' id='main_survey_message' defaultValue={defaultMainText}></TextareaAutosize>
                <p className='details'> Event Date: {window.activeEvent == undefined  ? 'Error' : moment(window.activeEvent.date).format('DD MMMM YYYY')} </p>
                {window.activeEvent != undefined  && window.activeEvent.location !== 'N/A' && window.activeEvent.location !== '' ?
                <p className='details'> Location: {window.activeEvent.location}</p>
                :
                <></>}
                <p className='details'> Number of Guests in Your Group: #</p>
                <p className='details'> Your unique link: https://placecard.com/beginSurvey/id=123456</p>
                <TextareaAutosize className='signature' id='closing_survey_message' defaultValue={defaultSignatureText}></TextareaAutosize>
            </Card>
            <section className='verticalFlex surveyBtns'>
                <section className='horizontalFlex'>
                    <Button variant='contained' className='blueBtn basicBtn fitBtn' onClick={previewSurvey}>Preview Survey</Button>
                    <Button variant='contained' className='blueBtn basicBtn fitBtn' onClick={() => history.push('/uploadGuestList')}>Edit Survey Recipients</Button>
                    <Button variant='contained' className='blueBtn basicBtn fitBtn' onClick={() => history.push('/editDetails')}>Edit Event Details</Button>
                </section>
                <section className='horizontalFlex pageNavBtns'>
                    <Button variant='contained' className='basicBtn' onClick={() => history.goBack()}>Back</Button>
                    <Button variant='contained' className='basicBtn' id='testBtn' disabled={isDisabled} onClick={sendSelfSurvey}>Send Test Email</Button>
                    <Button variant='contained' className='basicBtn' onClick={showWarning}>Send</Button>
                    <Modal title="Ready to Send?" text="We'll send this survey to all selected guests." no={hideWarning} yes={sendSurvey} />
                </section>
            </section>
        </section>
    );
}

async function emailSurveys(main_message: string, closing_message:string) {
    if (window.activeEvent) {
        let guestIds: string[] = [];
        if (window.activeEvent.toSend){
            guestIds = window.activeEvent.toSend;
        }
        if (window.activeEvent.remindersToSend){
            for (let x of window.activeEvent.remindersToSend) {
                if (!guestIds.includes(x) && x != undefined && x != null) {
                    guestIds.push(x);
                }
            }
        }
        let tmpIds: string[] = [];
        for (let x of guestIds) {
            if (x != undefined && x!= null && !tmpIds.includes(x)) {
                tmpIds.push(x);
            }
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                eventId: window.activeEvent.id,
                guestIds: tmpIds,
                main_message: main_message,
                closing_message: closing_message,
                host_name: window.firstNameState + " " + window.lastNameState
            })
        };
        return fetch(process.env.REACT_APP_BACKEND_URL + '/notifications/email/', requestOptions);
    }
}

async function emailTestSurvey(main_message: string, closing_message:string) {
    if (window.activeEvent) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                eventId: window.activeEvent.id,
                userEmail: window.emailState,
                main_message: main_message,
                closing_message: closing_message,
                host_name: window.firstNameState + " " + window.lastNameState
            })
        };
        return fetch(process.env.REACT_APP_BACKEND_URL + '/notifications/testEmail/', requestOptions);
    }
}

async function updateSentSurveys() {
    if (window.activeEvent) {
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                surveys_sent: window.activeEvent.toSend
            })
        };
        return fetch(process.env.REACT_APP_BACKEND_URL + '/events/updateEvent/'+window.activeEvent.id, requestOptions);
    }
}