import './Home.css';
import React, { useEffect } from "react";
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

export function Pricing() {

    const history = useHistory();

    const openSignupDialogBox = () => {
        history.push('/newAccount');
    };
    
    return (<>
        <section>
            <h2 id='pricingTitle'>How It Works</h2>
            <img className="pricingImg" src="https://c.tenor.com/JX-KI9Kz_GIAAAAC/for-free-adam-sandler.gif" alt="Pricing Meme"/>
            <div className="pricingText">
                <p className="pricingText">
                    For a limited time, Placecard is 100% free to use. 
                </p>
                <p className="pricingText">
                    We’re just getting started with our Beta launch and would love for you to check it out. 
                    No downloads, no credit cards, no obligations. 
                </p>
                <p className="pricingText">
                    Just a new, easy, free way to create a seating chart. 
                </p>
            </div>
            <Button variant='contained' className="signupBtn" onClick={openSignupDialogBox} color='info'>Get Started - it's free!</Button>
        </section>
    </>);
}