import { useHistory, useLocation } from "react-router-dom";
import TextField from '@mui/material/TextField';
import './guestPages.css';
import moment from "moment";
import { Button } from "@mui/material";
import validator from "validator";
import React from "react";
import { userSignOut } from "../../utils/firebase/firebaseAuth";


export function GuestConfirmation() {
    // sign out the user
    userSignOut();
    window.setLoggedIn(false);
    window.setFirstTime(undefined);
    window.setGuestMode(true);
    const history = useHistory();
    const queryString = useLocation().search;
    // gets query string if you do /beginSurvey?guestId=aaaaaa&eventId=12345
    const guestID = new URLSearchParams(queryString).get('guestId');
    const eventID = new URLSearchParams(queryString).get('eventId');
    const [inviterName, setInviter] = React.useState('Someone');
    const [eventName, setEventName] = React.useState('');
    const setVars = async () => {
        try {
            const eventInfo = await fetch(process.env.REACT_APP_BACKEND_URL + '/events/guestAccess/' + eventID);
            const eventData = await eventInfo.json();
            const dbUser = await fetch(process.env.REACT_APP_BACKEND_URL + '/users/getFirebaseUser/' + eventData._userId);
            const jsonRes = await dbUser.json();
            setInviter(jsonRes.first_name + ' ' + jsonRes.last_name);
            setEventName(eventData.event_name);
        } catch (e) {
            const linkErr = document.getElementById('wrongLinkError');
            console.error(e);
            if (linkErr !== null) {
                linkErr.style.display = 'block';
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }
        }
    }
    setVars();
    const goHome = () => {
        history.push('/');
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const contact = validator.trim(event?.target?.contactInfo?.value).toLowerCase();
        try {
            const guestInfo = await fetch(process.env.REACT_APP_BACKEND_URL + '/guests/' + guestID);
            const eventInfo = await fetch(process.env.REACT_APP_BACKEND_URL + '/events/guestAccess/' + eventID);
            const eventData = await eventInfo.json();
            const guests = [];
            const guestFetch = await fetch(process.env.REACT_APP_BACKEND_URL + '/events/guests/' + eventID);
            const fetchedGuests = await guestFetch.json();
            for (let guest of fetchedGuests) {
                const newGuest = {
                    id: guest._id,
                    name: guest.first_name + ' ' + guest.last_name,
                    groupID: guest.group_id,
                    groupName: guest.group_name,
                    groupSize: guest.party_size,
                    contact: guest.email,
                }
                guests.push(newGuest);
            }
            window.setActiveEvent({
                id: undefined,
                uid: undefined,
                name: eventData.event_name,
                date: (new Date(eventData.event_start_time)).toLocaleString().split(',')[0],
                time: moment(new Date(eventData.event_start_time)).format('h:mm a'),
                location: eventData.location,
                perTable: eventData.attendees_per_table,
                guestList: undefined,
                tables: undefined,
            });
            window.setInvitees(guests);

            if (guestInfo.status === 200) {
                const data = await guestInfo.json();
                
                const newGuest = {
                    id: data._id,
                    name: data.first_name + " " + data.last_name,
                    groupID: data.group_id,
                    groupName: data.group_name,
                    groupSize: data.party_size,
                    contact: data.email,
                };

                if ((data.email.toLowerCase() === contact || data.phone_number === contact) && contact !== '' && contact != undefined) {
                    window.setCurGuest(newGuest);
                    window.setGroupID(data.group_id);
                    window.setDisliked(data.survey_response && data.survey_response.disliked ? data.survey_response.disliked : []);
                    window.setLiked(data.survey_response && data.survey_response.liked ? data.survey_response.liked : []);
                    window.setLoved(data.survey_response && data.survey_response.ideal ? data.survey_response.ideal : []);
                    const link = '/takeSurvey?page=0&guestId=' + guestID + '&eventId=' + eventID;
                    history.push(link);
                    window.location.hash = '#page0';
                }
                else {
                    const contactErr = document.getElementById('wrongContactError');
                    if (contactErr !== null) {
                        contactErr.style.display = 'block';
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }
                }
            }
            else {
                const linkErr = document.getElementById('wrongLinkError');
                if (linkErr !== null) {
                    linkErr.style.display = 'block';
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }
            }
        }
        catch (e) {
            const linkErr = document.getElementById('wrongLinkError');
            console.error(e);
            if (linkErr !== null) {
                linkErr.style.display = 'block';
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }
        }
    }

    return (
        <section className='guestConfirmation'>
            <h1 className='title'>Welcome to Placecard!</h1>
            <p className='subtitle'>{inviterName} would like to know your seating preferences for their upcoming event{eventName == '' ? '.' : ' ' + eventName + '.'}</p>
            <p className='subtitle'>Please enter your email address to continue.</p>
            <p className='pageError' id='wrongContactError'>Incorrect contact information. Please try again.</p>
            <p className='pageError' id='wrongLinkError'>There is a problem with your link. Please try again.</p>
            <form onSubmit={handleSubmit}>
                <section className='decoratedTextField'>
                    <TextField label='Email Address' name='contactInfo' size='small' className='textInput' />
                </section>
                <section className='horizontalContainer twoBtns'>
                    <Button className='basicBtn' variant='contained' onClick={goHome}>Home</Button>
                    <Button className='basicBtn' variant='contained' type='submit'>Next</Button>
                </section>
            </form>
        </section>
    );
}

