import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import { useEffect, useRef, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { addGuestToGlobalEvent, updateGuestToGlobalEvent, deleteGuestFromGlobalEvent, sendSurveyToGuestFromGlobalEvent } from "../guestList/AddGuestPopUp";
import { ObjectId } from 'bson';
import Tooltip from '@mui/material/Tooltip';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import './GuestListTable.css'
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';

export interface GuestListDataInterface {
	individualName?:	string;
	groupName?:			string;
	groupContact:		string | undefined;
	groupSize:			string | undefined;
	sendSurvey:			boolean;
	surveyStatus?:		"Completed" | "Pending" | "Not Sent" | "Failed to Send" | "N/A"
	last_sent:			string;
	groupMembers:		any[];
	id?: 				string;
	group_id?:			string;
}

export type GuestListTableMode = "SurveySent" | "New"

function GuestListTableRow(props: {tableData:GuestListDataInterface[], guest:GuestListDataInterface, setTableData:Function, index:number, mode?:GuestListTableMode}){
	const [open, setOpen]		= useState(false)
	const [editing, setEditing]	= useState(false)
	const inputRef = useRef<HTMLInputElement>(null);

	// #region Mobile Detection
	const [width, setWidth] = useState<number>(window.innerWidth);
	const [openSave, setOpenSave] = useState(false);
	const [saveMessage, setSaveMessage] = useState(true);
	const [openError, setOpenError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(true);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

	const handleSaveClose = () => {
		setOpenSave(false);
	};

	const handleSaveOpen = () => {
		setOpenSave(true);
	};

	const handleErrorClose = () => {
		setOpenError(false);
	};

	const handleErrorOpen = () => {
		setOpenError(true);
	};

	const isMobile = width <= 768;
	// #endregion

	function updateRowData(key: string, value: any){
		props.setTableData(
			[
				...props.tableData.slice(0, props.index),
				{
					...props.tableData[props.index],
					[key]: value,
				},
				...props.tableData.slice(props.index+1)
			]
		)
	}

	function deleteRowData(){
		// delete the person or group from the database
		const requestOptions = {method: 'DELETE',}
		if (rowIsGroup){
			for (let member of props.tableData[props.index].groupMembers){
				fetch(process.env.REACT_APP_BACKEND_URL + '/guests/' + member.guest_id, requestOptions)
				// TODO: This should not happen in a loop, we should be able to make one api call to delete all guests
			}
			deleteGuestFromGlobalEvent(props.tableData[props.index].groupMembers.map((item:any):string => item.guest_id.toString()))
			sendSurveyToGuestFromGlobalEvent(props.tableData[props.index].groupMembers.map((item:any):string => item.guest_id.toString()), "DELETE")
		}
		else {
			let idToDelete = props.tableData[props.index].id || ""
			fetch(process.env.REACT_APP_BACKEND_URL + '/guests/' + idToDelete, requestOptions)
			deleteGuestFromGlobalEvent(idToDelete.toString())
			sendSurveyToGuestFromGlobalEvent(idToDelete.toString(), "DELETE")
		}
		setEditing(false)
		props.setTableData(
			[
				...props.tableData.slice(0, props.index),
				...props.tableData.slice(props.index+1)
			]
		)
	}

	function handleChange(event: any, guest: any) {
		if (window.activeEvent) {
			if (event.target.checked && guest.last_sent == 'N/A') {
				if (window.activeEvent.toSend){
					window.activeEvent.toSend.push(guest.id)
				}
				else {
					window.activeEvent.toSend = [guest.id];
				}
			}
			else if (event.target.checked && guest.last_sent != 'N/A') {
				if (window.activeEvent.remindersToSend){
					window.activeEvent.remindersToSend.push(guest.id)
				}
				else {
					window.activeEvent.remindersToSend = [guest.id];
				}
			}
			else if (!event.target.checked && guest.last_sent == 'N/A') {
				if (window.activeEvent.toSend) {
					window.activeEvent.toSend = window.activeEvent.toSend.filter(function(id) {
						return id!=guest.id;
					});
				}
				else {
					window.activeEvent.toSend = [];
				}
			}
			else{
				if (window.activeEvent.remindersToSend) {
					window.activeEvent.remindersToSend = window.activeEvent.remindersToSend.filter(function(id) {
						return id!=guest.id;
					});
				}
				else {
					window.activeEvent.remindersToSend = [];
				}
			}
		}
	}

	function determineStatus() {
		if (props.guest.last_sent == "N/A"){
			return "Not Sent";
		}
		else if (window.activeEvent && window.activeEvent.respondents){
			return window.activeEvent.respondents.indexOf(props.guest.id ? props.guest.id : '') !== -1 ?
			'Completed' : 'Pending'
		}


	}

	function expandNameContactColumn(){
		return(
			<>
				<td className="firstCol" onClick={() => setOpen(!open)}>
					{rowIsGroup && <IconButton className='guestListIcon'>{open ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}</IconButton>}
				</td>
				<td className='nameCol' onDoubleClick={() => {console.log('fire'); setEditing(true);}}>
					{editing?
						<>
							<TextField
								value={rowIsGroup ? props.guest.groupName : props.guest.individualName}
								onChange={(e) => updateRowData((rowIsGroup ? "groupName" : "individualName"), e.target.value)}
								size="small">
							</TextField>
							{isMobile ?
								<TextField
									value={props.guest.groupContact}
									onChange={(e) => updateRowData("groupContact", e.target.value)}
									size="small">
								</TextField>
								:
								<></>
							}
						</>
						:
						<>
							{rowIsGroup ?
								<span >{props.guest.groupName}</span>
								:
								<span >{props.guest.individualName}</span>
							}
							{isMobile ?
								<p className="guestContact">{props.guest.groupContact}</p>
								:
								<></>
							}

							<span hidden={saveMessage}>
								<Tooltip open={openSave} onClose={handleSaveClose} onOpen={handleSaveOpen} title={"Your changes have been saved!"} placement="bottom">
									<CheckCircleOutlineIcon fontSize="small" className="saveCheck"/>
								</Tooltip>
							</span>
							<span hidden={errorMessage}>
								<Tooltip open={openError} onClose={handleErrorClose} onOpen={handleErrorOpen} title={"Oh no, something went wrong! This change will not be saved, please check your input and try again!"} placement="bottom">
									<ErrorOutlineIcon fontSize="small" className="errorCheck"/>
								</Tooltip>
							</span>
						</>
					}
				</td>
				{
					isMobile ?
					<></>
					:
					<td className='contactCol'>
						{editing?
							<TextField value={props.guest.groupContact} onChange={(e) => updateRowData("groupContact", e.target.value)} size="small"></TextField>
							:
							<span onDoubleClick={() => setEditing(true)}>{props.guest.groupContact}</span>
						}
					</td>
				}
			</>

		)
	}

	// function chooseIcon(){
	// 	let status = window.activeEvent !== null && window.activeEvent.surveys != undefined  && window.activeEvent.surveys.indexOf(props.guest.id != undefined  ? props.guest.id : '') !== -1 ? (window.activeEvent.respondents != undefined  && window.activeEvent.respondents.indexOf(props.guest.id != undefined  ? props.guest.id : '') !== -1 ? 'Completed' : 'Pending') : 'Not Sent'
	// 	if (status=="Not Sent"){
	// 		return(<td><IconButton className='guestListIcon' aria-label="Send email" title="Send email"><SendIcon/></IconButton></td>)
	// 	}
	// 	else if (status=="Pending"){
	// 		return(<td><IconButton className='guestListIcon' aria-label="Send reminder"><NotificationsIcon/></IconButton></td>)
	// 	}
	// 	else if (status=="Completed"){
	// 		return(<td><IconButton className='guestListIcon' aria-label="Completed"><MarkEmailReadIcon/></IconButton></td>)
	// 	}
	// }

	function editSaveDeleteButton(){
		return(
			<>
				<td className='editButtonCell'>
					<IconButton
						className='guestListIcon'
						onClick={() => {
							if(editing){
								if(!rowIsGroup){ //if row is individual, update individual
									updateGuestToGlobalEvent(props.guest, props.guest.id?.toString());
									const nameSplit = (props && props.guest && props.guest.individualName)? props.guest.individualName.split(" ") : [];
									let firstName = '';
									let lastName = '--';
									if (nameSplit.length > 1) {
										firstName = nameSplit.slice(0, nameSplit.length - 1).join(" ");
										lastName = nameSplit[nameSplit.length - 1];
									}
									else {
										firstName = nameSplit[0];
									}

									const requestOptions = {
										method: 'PATCH',
										headers: { 'Content-Type': 'application/json' },
										body: JSON.stringify({
											first_name: firstName,
											last_name: lastName,
											email: props.guest.groupContact,
											party_size: props.guest.groupMembers.length,
											_id: props.guest.id?.toString()
										})
									};
									fetch(process.env.REACT_APP_BACKEND_URL + '/guests/updateGuest', requestOptions).then((res) => {
										let status = res.status;
										if (status == 200){
											setErrorMessage(true);
											setSaveMessage(false);
										}
										else {
											setErrorMessage(false);
											setSaveMessage(true);
										}
									})

								}
								else {	//eles, row is group, must update all group memebrs.
									// let updatedGroupName = props.guest.groupName

									for (let groupMember of props.guest.groupMembers){
										const updatedGuestInfo = {
											"groupContact":		props.guest.groupContact,
											"group_id":			props.guest.group_id,
											"group_name":		props.guest.groupName,
											"party_size":		props.guest.groupMembers.length,
											"id":				groupMember.guest_id.toString(),
											"individualName":	groupMember.guest_name,
										}
										const nameSplit = groupMember.guest_name.split(" ");
										let firstName = '';
										let lastName = '--';
										if (nameSplit.length > 1) {
											firstName = nameSplit.slice(0, nameSplit.length - 1).join(" ");
											lastName = nameSplit[nameSplit.length - 1];
										}
										else {
											firstName = nameSplit[0];
										}

										const currIds = window.activeEvent?.guestList.map( guest => guest.id )
										if (!currIds?.includes(groupMember.guest_id.toString()) ){		//if the current guest list does not include the current id, this means the guest was added to a group in the table.
											addGuestToGlobalEvent({
												"_id":			groupMember.guest_id.toString(),
												"first_name":	firstName,
												"last_name":	lastName,
												"group_id":		props.guest.group_id,
												"group_name":	props.guest.groupName,
												"party_size":	props.guest.groupMembers.length,
												"email":		props.guest.groupContact,
												"last_sent": String(new Date())
											}) //TODO change addGuestToGlobalEvent to be able to take in updatedGuestInfo object seen above.

											const requestOptions = {
												method: 'POST',
												headers: { 'Content-Type': 'application/json' },
												body: JSON.stringify({
													"first_name":				firstName,
													"last_name":				lastName,
													"_id":						groupMember.guest_id.toString(),
													"email":					props.guest.groupContact,
													"party_size":				props.guest.groupMembers.length,
													"associated_table_number":	-1,
													"group_id":					props.guest.group_id,
													"group_name":				props.guest.groupName,
													"last_sent": String(new Date())
												})
											}

											fetch(process.env.REACT_APP_BACKEND_URL + '/guests/newGuest/' + window.activeEvent?.id, requestOptions).then((res) => {
												let status = res.status;
												if (status == 200){
													setErrorMessage(true);
													setSaveMessage(false);
												}
												else {
													setErrorMessage(false);
													setSaveMessage(true);
												}
											})
										}
										else{
											updateGuestToGlobalEvent(updatedGuestInfo, groupMember.guest_id?.toString());
											const requestOptions = {
												method: 'PATCH',
												headers: { 'Content-Type': 'application/json' },
												body: JSON.stringify({
													first_name: firstName,
													last_name: lastName,
													email: props.guest.groupContact,
													party_size: props.guest.groupMembers.length,
													group_name: props.guest.groupName,
													_id: groupMember.guest_id?.toString()
												})
											};
											fetch(process.env.REACT_APP_BACKEND_URL + '/guests/updateGuest', requestOptions).then((res) => {
												let status = res.status;
												if (status == 200){
													setErrorMessage(true);
													setSaveMessage(false);
												}
												else {
													setErrorMessage(false);
													setSaveMessage(true);
												}
											})
										}
									}
								}
							};
							setEditing(!editing);
						}}
						aria-label={editing? "Save edits" : "Edit row"}>
						{editing? <SaveIcon/> : <EditIcon/>}
					</IconButton>
					{
						isMobile && editing?
							<IconButton className='guestListIcon' onClick={() => deleteRowData()} aria-label="Delete row">
								<DeleteIcon/>
							</IconButton>
						:
						<></>
					}
				</td>
			{ isMobile ? <></> :
				<td>
					<IconButton className='guestListIcon' onClick={() => deleteRowData()} aria-label="Delete row">
						<DeleteIcon/>
					</IconButton>
				</td>
	}
			</>
		)
	}

	const rowIsGroup = props.guest.groupMembers.length > 0
	return(
		<>
			<tr className='groupSummary'>
				{
					props.mode === "New" ?
						<>
							{expandNameContactColumn()}

							<td>
								<Checkbox
									checked={props.guest.sendSurvey}
									onChange={(e) => {
										updateRowData("sendSurvey", e.target.checked);
										sendSurveyToGuestFromGlobalEvent(rowIsGroup? (props.guest.groupMembers.map(item => item.guest_id.toString()) || [""]) : (props.guest.id?.toString() || ""), e.target.checked? "ADD":"DELETE" )
									}}/>
							</td>
							{editSaveDeleteButton()}

						</>
					:
						<>

							{expandNameContactColumn()}
							<td className='statusColumn'>{determineStatus()}</td>
							{/* {chooseIcon()} */}
							<td><Checkbox defaultChecked={false} onChange={(e) => handleChange(e, props.guest)} disabled={props.guest.groupContact=='N/A' || props.guest.groupContact=="--" || !props.guest.groupContact ? true : false}/></td>
							{isMobile? <></> : <td>{props.guest.last_sent=="N/A" ? "--" : props.guest.last_sent}</td> }
							{editSaveDeleteButton()}
						</>
				}
			</tr>
			<tr className='groupDropdown'>
				<td></td>
				<td colSpan={(props.mode === "New" ? 5 : 6) - (isMobile? 2 : 0)}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<ul className='subgroupMemberList' onDoubleClick={() => setEditing(true)}>
							{props.guest.groupMembers.map(({guest_name: memberName, guest_id: memberId}, i) =>(
								editing?
									<li className="subgroupMember" key={memberId}>
										<TextField value={memberName} onChange={(e) => {
												updateRowData("groupMembers", props.guest.groupMembers.slice(0, i)
													.concat([{"guest_name": e.target.value, "guest_id": memberId}])
													.concat(props.guest.groupMembers.slice(i+1)));
												}
											} size="small" inputRef={i == props.guest.groupMembers.length -1  ? inputRef : null} name={i.toString()}/>
										<IconButton
											className='guestListIcon'
											onClick={() => {
												const delID = props.guest.groupMembers[i].guest_id.toString();
												deleteGuestFromGlobalEvent(delID)
												updateRowData("groupMembers", props.guest.groupMembers.slice(0, i).concat(props.guest.groupMembers.slice(i+1)));
												const requestOptions = {method: 'DELETE',};
												fetch(process.env.REACT_APP_BACKEND_URL + '/guests/' + delID, requestOptions);
												sendSurveyToGuestFromGlobalEvent(delID, "DELETE")
											}}
											aria-label="Delete row">
												<DeleteIcon/>
										</IconButton>
									</li>
									:
									<li className="subgroupMember" key={memberId}>{memberName}</li>
							))}

							{ editing && (
								<section className="addButtonGroup">
									<Button color="primary" variant="contained" onClick={
										() => {
											updateRowData("groupMembers", props.guest.groupMembers.concat( [{"guest_name":  props.guest.groupName + " Member " + (props.guest.groupMembers.length + 1).toString(), "guest_id": (new ObjectId()).toString()}] ));
											window.requestAnimationFrame(function() {
												inputRef.current?.focus();
												inputRef.current?.select();
											}
												);

										}
									}>
										Add Member to Group
									</Button>
								</section>
							)}
						</ul>
					</Collapse>
				</td>
			</tr>
		</>
	)
}


export function GuestListTable(props: {tableData:GuestListDataInterface[], setTableData:Function, mode?:GuestListTableMode}) {
	// #region Mobile Detection
	const [width, setWidth] = useState<number>(window.innerWidth);
	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

	const isMobile = width <= 768;
	// #endregion

	return(
		<section className="guestListTable">
			<table className="resultTable">

				<thead>
					<tr className="headingRow">
						{
							props.mode === "New" ?
							<>
								<th className='firstCol'></th>
								<th className='firstColName'>Name of Individual/Group</th>
								{ isMobile ? <></> : <th className='firstColContact'>Contact</th> }
								<th className='sendSurveyColumnHeader'>Send Survey</th>
								{ isMobile ? <></> : <th className='editColumnHeader'>Edit</th> }
								{ isMobile ? <></> : <th className='deleteColumnHeader' >Delete</th> }
								<th className='lastCol'></th>
							</>
							:
							<>
								<th className='firstCol'></th>
								<th className='firstColName'>Name of Individual/Group</th>
								{ isMobile ? <></> : <th className='firstColContact'>Contact</th> }
								<th  className='midCol'>Survey Status</th>
								<th  className='midCol'>Select to Send</th>
								{ isMobile ? <></> : <th  className='midCol'>Last Sent</th> }
								{ isMobile ? <></> : <th  className='midCol'>Edit</th> }
								{ isMobile ? <></> : <th  className='midCol'>Delete</th> }
								<th className='lastCol'></th>
							</>
						}

					</tr>
				</thead>

				<tbody>
					{ props.tableData && props.tableData.map((obj, i) => (
						<GuestListTableRow tableData={props.tableData} guest={obj} setTableData={props.setTableData} index={i} mode={props.mode} key={i}/>
					)) }
				</tbody>

			</table>
		</section>
	)
}
