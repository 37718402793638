import './Profile.css'
import { useHistory } from "react-router-dom";
import validator from 'validator';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Button, InputAdornment, TextField, Box, Dialog, DialogTitle, DialogContent, DialogContentText, List, Link } from '@mui/material';
import React, { useEffect } from 'react';
import { FaGoogle } from 'react-icons/fa';
import { newAccount } from "../firebase/AuthProvider"
import { nanoid as uuid } from "nanoid";
import { YardRounded } from "@mui/icons-material";
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import placecardLogo from "../../assets/logo.png";
const sha256 = require('crypto-js/sha256');
const hmacSHA512 = require('crypto-js/hmac-sha512');
const Base64 = require('crypto-js/enc-base64');

let validFirst = true;
let validLast = true;
let validPhone = true;
let validEmail = true;
let validPass = true;
let validConfirm = true;
/* we don't want to show/hide errors before they press submit for the first time */
let firstTime = true;

/* variables that hold the textfield input */
let firstName = '';
let lastName = '';
let phone = '';
let password = '';
let confirm = '';
let pw = ''
let updateEvent: any;

export function EditAccount() {
    const history = useHistory();

    /* Variables to keep track of if each textField shows an error */
    const [firstError, setFirstError] = React.useState(!validFirst);
    const [lastError, setLastError] = React.useState(!validLast);
    const [emailError, setEmailError] = React.useState(!validEmail);
    const [passError, setPassError] = React.useState(!validPass);
    const [confirmError, setConfirmError] = React.useState(!validConfirm);
    const [open, setOpen] = React.useState(false);

    /* the following togglePassword and toggleConfirm functions make the eye appear with
        or without a slash and change the password from visible to invisible or vice versa */
    let toggleOnPassword = () => {
        togglePassword('passNoEye');
    };
    let toggleOffPassword = () => {
        togglePassword('passEye');
    }
    let toggleOnConfirm = () => {
        togglePassword('confirmNoEye');
    }
    let toggleOffConfirm = () => {
        togglePassword('confirmEye');
    }

    useEffect(() => {
        validFirst = true;
        validLast = true;
        validPhone = true;
        validEmail = true;
        validPass = true;
        validConfirm = true;
        setFirstError(!validFirst);
        setLastError(!validLast);
        setEmailError(!validEmail);
        setPassError(!validPass);
        setConfirmError(!validConfirm);
    }, [])

    /* functions that will show/remove errors based on user's input */
    let validateFirstName = (event: any, val?: string) => {
        if (val !== undefined) {
            firstName = val;
        }
        else {
            firstName = validator.trim(event.target.value);
        }
        if (!firstTime || val != undefined) {
            const valid = !validator.isEmpty(firstName) && validator.isAlphanumeric(firstName.replace('-', '')) && validator.isByteLength(firstName, { min: 2, max: undefined });
            validFirst = valid;
            setFirstError(!valid);
            checkAllErrors(firstName, lastName, phone, password, confirm);
        }
    }

    let validateLastName = (event: any, val?: string) => {
        if (val !== undefined) {
            lastName = val;
        }
        else {
            lastName = validator.trim(event.target.value);
        }
        if (!firstTime || val != undefined) {
            const valid = !validator.isEmpty(lastName) && validator.isAlphanumeric(lastName.replace('-', '')) && validator.isByteLength(lastName, { min: 2, max: undefined });
            validLast = valid;
            setLastError(!valid);
            checkAllErrors(firstName, lastName, phone, password, confirm);
        }
    }

    let validatePass = (event: any, val?: string) => {
        if (val !== undefined) {
            password = val;
        }
        else {
            password = event.target.value;
        }
        if (!firstTime || val != undefined) {
            const valid = (validator.isWhitelisted(password.toLowerCase(), 'abcdefghijklmnopqrstuvwxyz0123456789!@#$%&* ') &&
                validator.isStrongPassword(password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 0, returnScore: false }));
            validPass = valid;
            setPassError(!valid);
            if (confirm !== password) {
                validConfirm = false;
                setConfirmError(true);
            }
            else {
                validConfirm = true;
                setConfirmError(false);
            }
            checkAllErrors(firstName, lastName, phone, password, confirm);
        }
    }

    let validateConfirm = (event: any, val?: string) => {
        if (val !== undefined) {
            confirm = val;
        }
        else {
            confirm = event.target.value;
        }
        if (!firstTime || val != undefined) {
            const valid = confirm === password;
            validConfirm = valid;
            setConfirmError(!valid);
            checkAllErrors(firstName, lastName, phone, password, confirm);
        }
    }

    let handleSubmit = async (event: any) => {
        event.preventDefault();
        updateEvent = event;
        password = event?.target?.password?.value;
        if (password != ''){
            console.log("changing pw")
            setOpen(true);
        }
        else{
            updateAccount(event);
        }
    }

    let startEditFlow = async (event: any) => {
        event.preventDefault();
        setOpen(false);
        pw = event?.target?.password?.value;
        updateAccount(updateEvent);
    }

    /* takes care of validation and sending data to the database when the "Next" button is pressed */
    let updateAccount = async (data: any) => {
        const errors = document.getElementsByClassName('pageError');
        for (let i = 0; i < errors.length; i++) {
            if (errors[i] !== null) {
                (errors[i] as HTMLElement).style.display = 'none';
            }
        }
        const x = document.getElementById('sendAccountError');
        if (x !== null) {
            x.style.display = 'none';
        }
        let errorFound = false;
        if (data == null) {
            if (x !== null) {
                x.style.display = 'inline-block';
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }
        }

        /* get data from form and fill variables */
        firstName = validator.trim(data?.target?.firstName?.value);
        lastName = validator.trim(data?.target?.lastName?.value);
        phone = '';
        password = data?.target?.password?.value;
        confirm = data?.target?.confirm?.value;

        /* begin validation process */
        if (firstName==''){
            firstName=window.firstNameState;
        }
        // else {
        //     validateFirstName(null, firstName);
        // }
        if (lastName==''){
            lastName=window.lastNameState;
        }
        // else {
        //     validateLastName(null, lastName);
        // }
        if (password!=''){
            validatePass(null, password)
            validateConfirm(null, confirm);
        }
        validateFirstName(null, firstName);
        validateLastName(null, lastName);
        console.log(validFirst, validLast, validPass, validConfirm)
        errorFound = !(validFirst && validLast && validPass && validConfirm);
        if (!errorFound) {
            /* if form is good, create the new account */
            try {
                /*check is anything was actually updated with the firstName or lastName*/
                if(firstName==window.firstNameState && lastName==window.lastNameState && password == ''){
                    let y = document.getElementById('noChangeFormError');
                    if (y) {
                        y.style.display = 'block';
                    }
                    return;
                }
                let res = await editAccount(firstName, lastName, password);

                window.setFirstName(firstName);
                window.setLastName(lastName);
                // log in with this data
                window.setLoggedIn(true);
                if (res){
                    const y = document.getElementById('success');
                    if (y !== null) {
                        y.style.display = 'inline-block';
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }
                    //history.push('/userDash');
                }
                else {
                    setOpen(false)
                    const y = document.getElementById('wrongPassword');
                    if (y !== null) {
                        y.style.display = 'inline-block';
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }
                   // history.push('/editAccount');
                }
            }
            catch {
                const z = document.getElementById('dupEmailError');
                if (z !== null) {
                    z.style.display = 'inline-block';
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }
                return;
            }
        }
        /* if there is an error, tell the user */
        else {
            const y = document.getElementById('accountFormError');
            if (y !== null) {
                y.style.display = 'inline-block';
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }
            firstTime = false;
        }
    }

    return (
        <>
            <h1 className='title'>Edit Your Account!</h1>
            <p className='subtitle'>Let's update your information:</p>
            <p className='subtitle pageError' id='noChangeFormError'>No changes were made.</p>
            <p className='subtitle pageError' id='accountFormError'>Please fix the errors.</p>
            <p className='subtitle pageError' id='dupEmailError'>An account already exists with that email. <br /> Please choose a different email or login.</p>
            <p className='subtitle pageError' id='sendAccountError'>Something went wrong. Please try again.</p>
            <p className='subtitle pageError' id='wrongPassword'>Wrong password, try again!</p>
            <p className='subtitle pageError' id='success'>Information successfully updated!</p>
            <form className='vertical-form' id="newAccountForm" onSubmit={handleSubmit}>
                <section className='formBox'>
                    <TextField
                        variant='outlined'
                        size='small'
                        type="text"
                        label="First Name"
                        InputLabelProps={{ shrink: true }}
                        name='firstName'
                        placeholder={window.firstNameState ? window.firstNameState : 'First Name'}
                        error={firstError}
                        helperText={firstError ? 'At least two characters (alphanumeric only)' : ''}
                        onChange={validateFirstName} />

                    <TextField
                        variant='outlined'
                        size='small'
                        type="text"
                        label='Last Name'
                        InputLabelProps={{ shrink: true }}
                        name='lastName'
                        placeholder={window.lastNameState ? window.lastNameState : 'Last Name'}
                        error={lastError}
                        helperText={lastError ? 'At least two characters (alphanumeric only)' : ''}
                        onChange={validateLastName} />

                    <TextField
                        variant='outlined'
                        size='small'
                        type="text"
                        label='Email'
                        InputLabelProps={{ shrink: true }}
                        name='email'
                        disabled={true}
                        placeholder={window.emailState ? window.emailState : 'Email'}
                        error={emailError}
                        helperText={emailError ? 'Please enter a valid email' : ''}
                        />
                        <TextField
                            id='passInp'
                            variant='outlined'
                            size='small'
                            type="password"
                            autoComplete='true'
                            name='password'
                            label='New Password'
                            error={passError}
                            helperText={passError ? <>At least 8 characters. Must contain: <br />1. One uppercase letter <br />2. One lowercase letter <br />3. One number <br />Can contain spaces and the following special symbols: [ ! @ # $ % & * ] </> : ''}
                            onChange={validatePass}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <AiFillEyeInvisible className='passEye' id='passNoEye' onClick={toggleOnPassword} />
                                        <AiFillEye className='passEye yesEye' id='passEye' onClick={toggleOffPassword} />
                                    </InputAdornment>
                            }} />

                        <TextField
                            id='passConfirm'
                            variant='outlined'
                            size='small'
                            type="password"
                            autoComplete='true'
                            label='Confirm Password'
                            name='confirm'
                            error={confirmError}
                            helperText={confirmError ? 'Passwords don\'t match' : ''}
                            onChange={validateConfirm}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <AiFillEyeInvisible className='passEye' id='confirmNoEye' onClick={toggleOnConfirm} />
                                        <AiFillEye className='passEye yesEye' id='confirmEye' onClick={toggleOffConfirm} />
                                    </InputAdornment>
                            }} />
                </section>
                <Button type='submit' className='basicBtn' variant='contained'>Update</Button>
            </form>
        <Dialog  open={open}>
            <DialogTitle>Verify Account</DialogTitle>
                <DialogContentText className="loginDialogText">
                    To update password please verify it is you!
                </DialogContentText>
            <List>
                <li className='verticalFlex'>
                    <p className='subtitle pageError' id='loginPageError'>Something went wrong. Please try again.</p>
                    <form onSubmit={startEditFlow} className='verticalFlex'>
                        <TextField
                            id='passInpLogin'
                            variant='outlined'
                            size='small'
                            className='loginInputs'
                            type="password"
                            autoComplete='true'
                            name='password'
                            label='Password'
                            error={passError}
                            helperText={passError ? 'Password must be at least 8 characters.' : ''}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <AiFillEyeInvisible className='passEye' id='passNoEyeLogin' onClick={toggleOnPassword} />
                                        <AiFillEye className='passEye yesEye' id='passEyeLogin' onClick={toggleOffPassword} />
                                    </InputAdornment>
                            }} />
                        <Button type="submit" className='basicBtn loginInputs' variant='contained'>Verify</Button>
                    </form>
                </li>

                <li>
                    <Link className="forgotPasswordLink" onClick={() => {/*setOpen(false);*/ history.push('/requestPasswordReset')}}>Forgot Password?</Link>
                </li>
            </List>
        </Dialog>
        </>
    );
}

/*
 * Sends the account information to the database.
 */
async function editAccount(first_name: string, last_name: string, password: string) {
    /* if there is a password change, update it in firebase */
    let authenticated = true;
    if (password != ''){
        const message: string = password.trim(); // ...
        const nonce: string = "CFd9zkLFmAN*q7tt4m#Vilhlh#32lZBfvAFaZkWyU4AxzsGZ$cMNfrBTjAF3uYF55LgriWOa22oM9LT&&7tdqzOvmy5IPwQZLsIQM9U00f";
        const path: string = "path";
        const privateKey: string = "epwEj#H0CzFWX3sRHIr4FV7UB8Cn8iBusWk5DIc9w2R5hj!!2jTy9LFEpBsKWcpX#pgjIgg3zJpYQCKOt@LInCuwo4Y9eXro&^jgl^hWus(pc";
        const hashDigest = sha256(nonce + message);
        const hashedPass = Base64.stringify(hmacSHA512(path + hashDigest, privateKey));
       
        const auth = getAuth();
        const user: any = auth.currentUser;
        
        /* ask user to verify with old password*/
        const hashedPW = Base64.stringify(hmacSHA512(path + sha256(nonce + pw.trim()), privateKey));

        /*create a credential for them and verify their identity */
        const credential = EmailAuthProvider.credential(window.emailState, hashedPW);

        /* if reauthentication is successful update the password */
        await reauthenticateWithCredential(user, credential).then(async () => {
            await updatePassword(user, hashedPass).then((resp) => {
                // Password reset has been confirmed and new password updated.
                console.log(resp)
                console.log("password good, change done")
                }).catch((error) => {
                    console.log(error)
                    // Error occurred during confirmation. The code might have expired or the
                    console.log("password bad, no change")
                });
        }).catch((error) => {
            console.log(error)
            authenticated = false;
            return false;
        });
    }
    if (authenticated) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                first_name: first_name,
                last_name: last_name,
                firebase_id: window.uidState
            })
        };

        // let res = await fetch(process.env.REACT_APP_BACKEND_URL + '/users/updateUser', requestOptions)
        let res = await fetch('http://localhost:3000/users/updateUser/', requestOptions)
        return res.ok;
    }
    else {
        return false;
    }
}

/*
 * Takes the necessary steps to hide/show the password to the user.
 * iconID is the HTML id of the icon that needs to be shown
 * inputID is the HTML id of the input box that needs its type to be changed
 * backupElem is the element that has been hidden but, if something goes wrong, will need to be shown again
 * type is the type that the input needs to change to. Should either be 'text' or 'password'
 */
function toggleHelper(iconID: string, inputID: string, backupElem: HTMLElement | null, type: string) {
    let x = document.getElementById(iconID);
    /* show the correct icon */
    if (x) {
        x.style.display = 'inline-block';
        /* change the type of the input */
        let y = document.getElementById(inputID);
        if (y) {
            y.setAttribute('type', type);
        }
    }
    /* if something went wrong, show the old element */
    else {
        if (backupElem) {
            backupElem.style.display = 'inline-block';
        }
    }
}

/*
 * Calls toggleHelper with the correct information based on the id parameter
 * in order to hide/show the password to the user.
 */
function togglePassword(id: string) {
    let e = document.getElementById(id);
    if (e) {
        e.style.display = 'none';
    }
    if (id === 'passNoEye') {
        toggleHelper('passEye', 'passInp', e, 'text');
    }
    else if (id === 'passEye') {
        toggleHelper('passNoEye', 'passInp', e, 'password');
    }
    else if (id === 'confirmEye') {
        toggleHelper('confirmNoEye', 'passConfirm', e, 'password');
    }
    else if (id === 'confirmNoEye') {
        toggleHelper('confirmEye', 'passConfirm', e, 'text');
    }
}

/*
 * Checks to see if there are any errors in order to show
 * or hide the error at the top of the page
 */
function checkAllErrors(firstName: string, lastName: string, phone: string, password: string, confirm: string) {
    const regex = new RegExp('[0-9]{3}-[0-9]{3}-[0-9]{4}');
    const error = (!validator.isEmpty(firstName) && validator.isAlphanumeric(firstName.replace('-', '')) && validator.isByteLength(firstName, { min: 2, max: undefined }))
        && (!validator.isEmpty(lastName) && validator.isAlphanumeric(lastName.replace('-', '')) && validator.isByteLength(lastName, { min: 2, max: undefined })) &&
        ((validator.isWhitelisted(phone, '0123456789') && phone.length === 10) || (validator.isWhitelisted(phone, '0123456789-') && phone.length === 12 &&
            regex.test(phone)) || (phone.length === 0)) && (validator.isWhitelisted(password.toLowerCase(), 'abcdefghijklmnopqrstuvwxyz0123456789!@#$%&* ') &&
                validator.isStrongPassword(password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 0, returnScore: false })) && confirm !== password;

    let x = document.getElementById('accountFormError');
    /* if there's an error, keep the error visible */
    if (error) {
        if (x !== null) {
            x.style.display = 'inline-block';
        }
    }
    /* otherwise, hide it */
    else {
        if (x !== null) {
            x.style.display = 'none';
        }
    }
}