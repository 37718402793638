import { useHistory } from "react-router-dom";
import './UserDashboard.css';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { Button, CircularProgress, IconButton, Switch } from "@mui/material";
import { AiFillPlusCircle } from "react-icons/ai";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { HiTrash } from 'react-icons/hi';
import { Modal } from '../../shared/Modal';

export function UserDashboard() {
    window.setGuestMode(false);
    const [viewAll, setView] = React.useState(false);
    const history = useHistory();
    const handleClick = async (event: PlacecardEvent) => {
        window.setActiveEvent(event);
        // get the event again so that we update the time
        const result = await fetch(process.env.REACT_APP_BACKEND_URL + '/events/' + event.id);
        if (result.ok) {
            const data = await result.json();
            const updatedEvent = {
                'id': data._id,
                'uid': data._userId,
                'name': data.event_name,
                'date': (new Date(data.event_start_time)).toLocaleString().split(',')[0],
                'time': (new Date(data.event_start_time)).toTimeString().split(' ')[0],
                'location': data.location,
                'tables': event.tables,
                'perTable': data.attendees_per_table,
                'guestList': event.guestList,
                'respondents': event.respondents,
                'surveys': data.surveys_sent,
                'toSend': data.surveys_to_send
            }
            window.setActiveEvent(updatedEvent);
            window.setInvitees(event.guestList);
        }
        else {
            console.error('Could not update event');
        }
        history.push('/eventDash');
    };

    const newEvent = () => {
        history.push('/createEvent');
    };

    // #region Mobile Detection
	const [width, setWidth] = useState<number>(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

	const isMobile = width <= 768;

	// #endregion
    // use https://mui.com/x/react-data-grid/column-definition/ for value
    function getEventDetails(params: any){
        return `${params.row.eventName} ${params.row.date}`
    }

    const columns = isMobile ?
    [
        {
            field: 'eventName',
            headerName: 'Your Events',
            flex:3,
            renderCell: (params: any) => {
                return(
                <>
                    <p>{params.row.eventName}</p>
                    <p className="dateSubtitle cellSubtitle">{params.row.date}</p>
                    <p className="countdownSubtitle cellSubtitle">{params.row.countdown}</p>

                </>
                )
            }
        },
        {
            field: 'countdown',
            headerName: 'Days Left',
            flex:1,
            hide: true
        },
        {
            field: 'dashboards',
            headerName: '',
            flex:2,
            renderCell: (params: any) => {
                return <Button className='basicBtn fitBtn dashBtn' onClick={() => handleClick(params.value)} size='small' variant='contained'>Dashboard</Button>
            }
        },
        {
            field: 'deletes',
            headerName: '',
            flex:1,
            renderCell: (params: any) => {
                return <IconButton onClick={() => showDelWarning(params.value)}><HiTrash /></IconButton>
            }
        }
    ]
    :
    [
        {
            field: 'eventName',
            headerName: 'Event Name',
            flex:4,
            // width: 98
        },
        {
            field: 'date',
            headerName: 'Date',
            flex:2
            // width: 148
        },
        {
            field: 'countdown',
            headerName: 'Days Left',
            flex:2
            // width: 148
        },
        {
            field: 'dashboards',
            headerName: '',
            flex:2,
            // width: 148,
            renderCell: (params: any) => {
                return <Button className='basicBtn fitBtn dashBtn' onClick={() => handleClick(params.value)} size='small' variant='contained'>Dashboard</Button>
            }
        },
        {
            field: 'deletes',
            headerName: '',
            flex:2,
            // width: 98,
            renderCell: (params: any) => {
                return <IconButton onClick={() => showDelWarning(params.value)}><HiTrash /></IconButton>
            }
        }
    ];


    const [rowsState, setRows]: [any, any] = React.useState([]);
    const [tableHeight, setTableHeight] = React.useState<number>(500)

    useLayoutEffect(() => {
        const rows: any = [];
        for (let event of window.eventsState) {
            const daysLeft = Math.ceil(((new Date(event.date)).valueOf() - (new Date()).valueOf()) / (60*60*24*1000));
            let daysLeftString = daysLeft.toString();
            if (daysLeft === 0) {
                daysLeftString = 'Today is the day!';
            }
            else if (daysLeft === 1) {
                daysLeftString = '1 Day Left!';
            }
            else if (daysLeft < 0) {
                daysLeftString = 'Event has ended.';
            }
            else if (isMobile){
                daysLeftString = daysLeft + ' days left'
            }
            if (daysLeft >= 0 || viewAll) {
                rows.push({
                    id: event.id,
                    eventName: event.name,
                    countdown: !isNaN(Number(daysLeftString)) ? Number(daysLeftString) : (daysLeftString),
                    date: moment(event.date).format('MM / DD / YYYY'),
                    dashboards: event,
                    deletes: event.id
                });
            }
        }
        setRows([...rows]);
        if (window.eventsState.length < 1) {
            setTableHeight(500)
        }
        else {
            isMobile ?
            setTableHeight(Math.min(500, (60 + (Math.max(1, rows.length) * 68))))
            :
            setTableHeight(Math.min(500, (60 + (Math.max(1, rows.length) * 56))))
        }

    }, [window.eventsState, viewAll]);

    const updateRows = () => {
        setView(!viewAll);
    };

    const showDelWarning = (eventId: string) => {
        const x = document.getElementById('hiddenWarning');
        if (x !== null) {
            x.style.display = 'block';
            x.classList.add(eventId);
        }
    }
    const del = async () => {
        hideWarning();
        const x = document.getElementById('hiddenWarning');
        let eventId: string = '';
        if (x !== null) {
            eventId = x.classList[x?.classList.length-1];
        }
        if (eventId !== '') {
            try {
                const confirmation = await deleteEvent(eventId);
                if (confirmation.status === 200) {
                    const events = [...window.eventsState];
                    const matchingId = (event: PlacecardEvent) => event.id === eventId;
                    const deletedInd = events.findIndex(matchingId);
                    events.splice(deletedInd, 1);
                    window.setEvents(events);
                    if (window.activeEvent !== null && window.activeEvent.id === eventId) {
                        window.setActiveEvent(undefined);
                    }
                }
                else {
                    console.error('Error: delete event failed');
                }
            }
            catch (e) {
                console.error(e);
            }
        }
    }

    const loadingCircle = () => {
        return (
            <section className='loadingCircle'>
                {window.eventsState === rowsState ? <p>No Events</p> :
                    <>
                        <p>Loading...</p>
                        <CircularProgress size={24} />
                    </>
                }
            </section>
        )
    }
    const hideWarning = () => {
        const x = document.getElementById('hiddenWarning');
        if (x !== null) {
            x.style.display = 'none';
        }
    }

    const hasEvent = () => {
        if (rowsState.length > 0) {
            return (
                <section className='userDashContent'>
                    <div style={{ 'height': tableHeight }} className='table'>
                        <DataGrid
                            rows={rowsState}
                            columns={columns}
                            disableColumnMenu={true}
                            hideFooter={true}
                            disableSelectionOnClick={true}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'countdown', sort: 'asc' }],
                                },
                            }}
                            components={{
                                NoRowsOverlay: loadingCircle,
                            }}
                        />
                    </div>

                    <section className='userDashFooter'>
                        <Button variant='contained' className='leftBtn' onClick={newEvent}><AiFillPlusCircle className='plusBtn' />Add New Event</Button>
                        <label>
                            <Switch onChange={updateRows} checked={!viewAll} />
                            Show Only Future Events
                        </label>
                    </section>
                </section>
            )
        }
        else {
            return (
                <section className='userDashContent'>
                    <h1 className='userDashText'>Create a new event!</h1>
                    <Button variant='contained' className='midBtn' onClick={newEvent}><AiFillPlusCircle className='plusBtn' />Add New Event</Button>
                </section>
            )
        }
    }

    return (
        <>
            <Modal title="Warning" text="You are about to delete this event." no={hideWarning} yes={del} />
            <h1 className='title'>Welcome back{window.firstNameState ? (window.firstNameState.trim() === '' ? '!' : ', ' + window.firstNameState + '!') : '!'}</h1>
            {hasEvent()}
        </>
    );
}

function deleteEvent(eventId: string) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(process.env.REACT_APP_BACKEND_URL + '/events/' + eventId, requestOptions);
}
