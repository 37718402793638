import './App.css';
/* Import Other Components */
import { Home } from './components/home/Home';
import { Pricing } from './components/home/Pricing';

/* Import Form Components */
import { NewAccount } from './components/forms/NewAccount';
import { CreateEvent } from './components/forms/CreateEvent';
/* Import Shared Components */
import { Footer } from './components/shared/Footer';
import { Header } from './components/shared/Header';
import { EditAccount } from './components/profile/EditAccount';
import { UserAccount } from './components/profile/UserAccount';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { EventDashboard } from './components/dashboards/eventDash/EventDashboard';
import { UserDashboard } from './components/dashboards/userDash/UserDashboard';
import { NotFound } from './components/shared/NotFound';
import { GuestList } from './components/guestList/GuestList';
import { EditSurvey } from './components/editPages/EditSurvey';
import { SeatingDashboard } from './components/dashboards/seatDash/SeatingDashboard';
import { GuestConfirmation } from './components/guestPages/GuestConfirmation';
import { SentConf } from './components/confirmationPages/SentConf';
import { EditDetails } from './components/editPages/EditDetails';
import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { FirebaseAuthProvider } from "./components/firebase/AuthProvider";
import { FullSurvey } from './components/guestPages/FullSurvey';
import { SurveyConf } from "./components/confirmationPages/SurveyConf";
import { EditGuestList } from './components/editPages/EditGuestList';
import { DevPage } from './components/devRoutes/DevPage';
import { RequestPasswordReset } from './components/profile/RequestPasswordReset'
import { PasswordReset } from './components/profile/PasswordReset'
import { EmailPasswordResetFail } from './components/profile/EmailPasswordResetFail'
import { EmailPasswordResetSuccess } from './components/profile/EmailPasswordResetSuccess'
import { PrivacyPolicy } from './components/policies/PrivacyPolicy'
import { TermsAndConditions } from './components/policies/TermsAndConditions'
import {reactLocalStorage} from 'reactjs-localstorage';

function App() {
  document.title = 'Placecard';
  [window.loggedInState, window.setLoggedIn] = React.useState(false);
  [window.firstNameState, window.setFirstName] = React.useState('');
  [window.lastNameState, window.setLastName] = React.useState('');
  [window.phoneState, window.setPhone] = React.useState('555-555-5555');
  [window.emailState, window.setEmail] = React.useState('email@email.com');
  [window.profPicState, window.setProfPic] = React.useState(null);
  [window.profPicNameState, window.setProfPicName] = React.useState('None');
  [window.eventsState, window.setEvents] = React.useState([]);
  [window.activeEvent, window.setActiveEvent] = React.useState(null);
  [window.inviteesState, window.setInvitees] = React.useState([]);
  [window.dislikedInvitees, window.setDisliked] = React.useState([{ id: 'none', name: '' }]);
  [window.likedInvitees, window.setLiked] = React.useState([{ id: 'none', name: '' }]);
  [window.lovedInvitees, window.setLoved] = React.useState([{ id: 'none', name: '' }]);
  [window.curGroupID, window.setGroupID] = React.useState(undefined);
  [window.curGuest, window.setCurGuest] = React.useState(undefined);
  [window.uidState, window.setUID] = React.useState('');
  [window.firstTime, window.setFirstTime] = React.useState(undefined);
  [window.removedMembers, window.setRemovedMembers] = React.useState([]);
  [window.guestMode, window.setGuestMode] = React.useState(false);

  let prevLocation : string = reactLocalStorage.get('prevLocation')
  
  useEffect(() => {
    const getEvents = async () => {
      try {
        if (window.location.href.indexOf('takeSurvey') === -1 && window.location.href.indexOf('beginSurvey') === -1 && window.uidState != undefined && window.uidState.trim() !== '') {
          const eventFetch = await fetch(process.env.REACT_APP_BACKEND_URL + '/events/users/' + window.uidState);
          const fetchedEvents = await eventFetch.json();
          const events: PlacecardEvent[] = [];
          for (let post of fetchedEvents) {
            const tables: any[] = post.tables;
            const guests: Invitee[] = [];
            let respondents = [];
            try {
              const guestFetch = await fetch(process.env.REACT_APP_BACKEND_URL + '/events/guests/' + post._id);
              const fetchedGuests = await guestFetch.json();

              for (let guest of fetchedGuests) {
                const newGuest = {
                  id: guest._id,
                  name: guest.first_name + ' ' + guest.last_name,
                  groupID: guest.group_id,
                  groupName: guest.group_name,
                  groupSize: guest.party_size,
                  contact: guest.email,
                  last_sent: guest.last_sent
                }
                if (guest.survey_response != undefined && (guest.survey_response.disliked.length !== 0 || guest.survey_response.ideal.length !== 0 || guest.survey_response.liked.length !== 0)) {
                  respondents.push(guest._id);
                }
                guests.push(newGuest);
                for (let x of tables) {
                  if (x.guests.includes(newGuest.id)) {
                    x.guests[x.guests.indexOf(newGuest.id)] = newGuest;
                    break;
                  }
                }
              }
              const event = {
                'id': post._id,
                'uid': post._userId,
                'name': post.event_name,
                'date': (new Date(post.event_start_time)).toLocaleString().split(',')[0],
                'time': (new Date(post.event_start_time)).toTimeString().split(' ')[0],
                'location': post.location,
                'tables': tables,
                'perTable': post.attendees_per_table,
                'guestList': guests,
                'respondents': respondents,
                'surveys': post.surveys_sent,
                'toSend': post.surveys_to_send
              };
              events.push(event);
            }
            catch (e) {
              console.error("Error: could not fetch guests for event with id " + post._id + ". " + e);
            }
          }

          window.setEvents([...events]);
          if (events.length > 0 && window.activeEvent == null) {
            window.setActiveEvent(events[0]);
            window.setInvitees(events[0].guestList);
          }
        }
      }
      catch (e) {
        console.error('Error: Could not load events for user. ' + e);
      }

    };
    getEvents();
  }, [window.uidState, window.guestMode]);

  const theme = createTheme({
    palette: {
      secondary: {
        main: '#1F6BAE'
      },
      primary: {
        main: '#00A6A0'
      },
      info: {
        main: '#F57A75',
        contrastText: '#fff',
      },
      error: {
        main: '#EB645E'
      }
    },
    typography: {
      fontFamily: 'Ubuntu'
    }
  });

  window.addEventListener('beforeunload', (event) => {
        event.preventDefault();
        reactLocalStorage.set("prevLocation", window.location.href)
  });
 
  
  if (!prevLocation){
    prevLocation = "/userHome"
  }

  return (
    <FirebaseAuthProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <section className='content'>
            <Header />
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/pricing' component={Pricing} />
              <Route exact path='/newAccount' component={ NewAccount }/>
              <Route exact path='/createEvent' component={ CreateEvent }/>
              <Route exact path='/eventDash' component={ EventDashboard }/>
              <Route path="/404" component={ NotFound }/>
              <Route exact path='/userHome' component={ UserDashboard }/>
              <Route exact path='/userDash' component={ UserAccount }/>
              <Route exact path='/editAccount' component={ EditAccount }/>
              <Route exact path='/editSurvey' component={ EditSurvey }/>
              <Route exact path='/sentConf' component={ SentConf }/>
              <Route exact path='/seatDash' component={ SeatingDashboard }/>
              <Route path='/beginSurvey' component={ GuestConfirmation }/>
              <Route path='/takeSurvey' component={FullSurvey}/>
              <Route exact path='/editDetails' component={ EditDetails }/>
              <Route exact path='/uploadGuestList' component={ GuestList }/>
              <Route exact path='/editGuestList' component={ EditGuestList }/>
              <Route exact path='/devRoutes' component={ DevPage }/>
              <Route exact path='/requestPasswordReset' component={ RequestPasswordReset }/>
              <Route exact path='/emailPasswordResetFail' component={ EmailPasswordResetFail }/>
              <Route exact path='/emailPasswordResetSuccess' component={ EmailPasswordResetSuccess }/>
              <Route exact path='/resetPassword' component={ PasswordReset }/>
              <Route exact path='/privacyPolicy' component={ PrivacyPolicy }/>
              <Route exact path='/termsAndConditions' component={ TermsAndConditions }/>
              <Redirect to={prevLocation}/>
            </Switch>
          </section>
          <Footer />
        </Router>
      </ThemeProvider>
    </FirebaseAuthProvider>
  );
}

export default App;
