import { Button, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { nanoid as uuid } from "nanoid";
import React from "react";
import './DevPage.css';
const crypto = require('crypto');

export function DevPage() {

    const showDetails = (userDetails: any) => {
        setnEvents(userDetails.n_events);
        setUserName(userDetails.user.name);
        setContact(userDetails.user.email);
        setEvents(userDetails.events);
    }

    const [nEvents, setnEvents] = React.useState(0);
    const [userName, setUserName] = React.useState('No User Selected');
    const [contact, setContact] = React.useState('No User Selected');
    const [events, setEvents]: [any[], any] = React.useState([]);

    const columns = [
        {
            field: 'yay',
            headerName: '*',
            width: 50
        },
        {
            field: 'inactive',
            headerName: 'X',
            width: 50
        },
        {
            field: 'userName',
            headerName: 'User',
            width: 178
        },
        {
            field: 'details',
            headerName: 'Details',
            width: 148,
            renderCell: (params: any) => {
                return <Button onClick={() => showDetails(params.value)}>View Details</Button>
            }
        },
        {
            field: 'lastLogin',
            headerName: 'Last Login',
            width: 198,
        },
        {
            field: 'contact',
            headerName: 'Email',
            width: 258,
            renderCell: (params: any) => {
                return <a href={'mailto:' + params.value}>{params.value}</a>
            }
        }
    ];

    const [rowsState, setRows]: [any, any] = React.useState([]);
    const [totalEvents, setTotalEvents] = React.useState(0);
    const [totalGuests, setTotalGuests] = React.useState(0);
    const [totalSurveys, setTotalSurveys] = React.useState(0);
    const [totalNone, setTotalNone] = React.useState(0);
    const [totalInactive, setTotalInactive] = React.useState(0);

    const handleClick = async () => {
        const rows: any = [];
        const res = await getAnalytics();
        const rawData: any = await res.json();
        const algorithm = "aes-256-cbc";
        let events = 0;
        let guests = 0;
        let surveys = 0;
        let noEvents = 0;
        let inactive = 0;
        const pass = document.getElementById('refreshPassword');
        if (pass) {
            try {
                const testEncrypt = '7e0685f6f2d0cba5c964b31b3b8eb735';
                const testDecipher = crypto.createDecipheriv(algorithm, 'c7de00e5f2d07456456131c1596e7eb4', (pass as HTMLInputElement).value);
                let testDecrypt = testDecipher.update(testEncrypt, "hex", "utf-8");
                testDecrypt += testDecipher.final("utf-8");
                if (testDecrypt != 'placecard') {
                    throw 'Error! Wrong password!';
                }
                else {
                    const x = document.getElementById("devPassError");
                    if (x) {
                        x.style.display = "none";
                    }
                    const decipher = crypto.createDecipheriv(algorithm, 'c7de00e5f2d07456456131c1596e7eb4', (pass as HTMLInputElement).value);
                    let decrypted = decipher.update(rawData.d, "hex", "utf-8");

                    decrypted += decipher.final("utf-8");
                    const data = JSON.parse(decrypted);
                    const testingEmails = ['ktsharo@verizon.net', 'alex.rubino@placecard.com', 'kaiqi.chee@placecard.com', 'calebonthemove@gmail.com', '670352@aacps.org', 'ookayleb@gmail.com', 'austriag29@gmail.com', 'attiq.amjad@placecard.com', 'attiq.amjad@gmail.com', 'rubinoalex5@gmail.com', 'kaiqichee@test.com', 'kchee@test.com', 'kaitlyn.sharo@placecard.com', 'jjsharo572@yahoo.com', 'amna.ahmad085@gmail.com', 'bkponna@gmail.com', 'hbaldwi1@stevens.edu', 'jnaeher@stevens.edu', 'valentina2000bustamante@gmail.com', 'bonniefaber4@gmail.com'];
                    for (let user of data) {
                        let yay = '';
                        let boo = '';
                        if (!testingEmails.includes(user.user.email)) {
                            let lastLogin = 'Never';
                            if (user.events.length === 0) {
                                noEvents += 1;
                                inactive += 1;
                                boo = 'x';
                            }
                            for (let event of user.events) {
                                events += 1;
                                guests += event.n_guests;
                                surveys += event.n_surveys;
                                if (event.uniqueEmails > 12) {
                                    yay = '*';
                                }
                                if (lastLogin == 'Never') {
                                    lastLogin = event.last_viewed;
                                } else {
                                    if (new Date(lastLogin) < new Date(event.last_viewed)) {
                                        lastLogin = event.last_viewed;
                                    }
                                }
                            }
                            if ((new Date(lastLogin)) < (new Date(moment().subtract(3, 'weeks').toDate()))) {
                                inactive += 1;
                                boo = 'x';
                            }
                            rows.push({
                                id: uuid(),
                                yay: yay,
                                inactive: boo,
                                userName: user.user.name,
                                contact: user.user.email,
                                lastLogin: lastLogin,
                                details: user
                            });
                        }
                    }
                    setRows([...rows]);
                    setTotalEvents(events);
                    setTotalGuests(guests);
                    setTotalSurveys(surveys);
                    setTotalNone(noEvents);
                    setTotalInactive(inactive);
                }

            } catch (e) {
                const x = document.getElementById("devPassError");
                if (x) {
                    x.style.display = "block";
                }
                console.log('wrong password!')
            }
        }
        else {
            console.log('No Password Element Found')
        }

    };

    return (
        <>
            <Card className='topCard seatDashCard devHeader'>
                <CardHeader title={'Metrics Bar'} className='cardHeader' />
                {/* Header Code  */}
                <Grid container className='dashBody firstCard' spacing={0} columns={26}>
                    <Grid item xs={4}>
                        <h3 className='seatStat'>{rowsState.length}</h3>
                        <p className='statLabel'>{rowsState.length===1 ? "User" : "Users"}</p>                    
                    </Grid>
                    <Grid item xs={4}>
                        <h3 className='seatStat'>{totalEvents}</h3>
                        <p className='statLabel'>{totalEvents===1 ? "Event" : "Events"}</p>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 className='seatStat'>{totalSurveys}</h3>
                        <p className='statLabel'>{totalSurveys===1 ? "Survey Sent" : "Surveys Sent"}</p>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 className='seatStat'>{isNaN((totalGuests/totalEvents)) ? 0 : (totalGuests/totalEvents).toFixed(2)}</h3>
                        <p className='statLabel'>Avg. # Guests</p>
                    </Grid>
                    <Grid item xs={3} className='badHeader'>
                        <h3 className='seatStat'>{totalNone}</h3>
                        <p className='statLabel'>{totalNone===1 ? "User" : "Users"} with no events</p>
                    </Grid>
                    <Grid item xs={3} className='badHeader'>
                        <h3 className='seatStat'>{totalInactive}</h3>
                        <p className='statLabel'>Inactive {totalNone===1 ? "User" : "Users"}</p>
                    </Grid>
                    <Grid item xs={3} className='badHeader'>
                        <h3 className='seatStat'>{isNaN(totalInactive/rowsState.length) ? 0 : (totalInactive/rowsState.length).toFixed(2)}</h3>
                        <p className='statLabel'>% Inactive</p>
                    </Grid>
                </Grid>
            </Card>
            <section id='showDevDetails'>
                <div style={{ 'height': 500 }} className='devTable'>
                    <DataGrid
                        rows={rowsState}
                        columns={columns}
                        disableColumnMenu={true}
                        hideFooter={true}
                        disableSelectionOnClick={true}
                    />
                </div>
                <Card id='detailsAside'>
                    <CardHeader className='innerBoxHeader' title={'Details'} />
                    <CardContent className='innerBoxContent'>
                        <ul>
                            <li>
                                Name: {userName}
                            </li>
                            <li>
                                Email: {contact}
                            </li>
                            <li>
                                Number of Events: {nEvents}
                            </li>
                        </ul>
                        <h3>Events</h3>
                        {events.length === 0 && <p>No Events</p>}
                        {events.map((event) => {
                            return (<>
                                <details className="eSummary">
                                    <summary>{event.name}</summary>
                                    <hr/>
                                    <p>Date: {event.date}</p>
                                    <p>Number of Guests: {event.n_guests}</p>
                                    <p>Surveys Sent: {event.n_surveys}</p>
                                    <p>Unique Guest Emails: {event.uniqueEmails}</p>
                                    <p>Last Viewed: {event.last_viewed}</p>
                                </details>
                            </>)
                        })}
                    </CardContent>
                </Card>
            </section>
            <section className='passwordSection'>
                <p className="subtitle pageError" id='devPassError'>Wrong Password!</p>
                <div className='verticalFlex topPadding'>
                    <label>Password</label>
                    <input type='text' id='refreshPassword' />
                </div>
                <Button onClick={handleClick}>Refresh</Button>
            </section>
        </>);
}

/*
 * Get analytics data from the database
 */
async function getAnalytics() {
    const userData = await fetch(process.env.REACT_APP_BACKEND_URL + '/dev/allUserData');
    return userData;
}