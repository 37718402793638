import { Button, InputAdornment, Link, TextField } from "@mui/material";
import { verifyPasswordResetCode, getAuth, confirmPasswordReset } from "firebase/auth"
import { useState } from "react";
import { AiFillEye, AiFillEyeInvisible, AiOutlineConsoleSql } from "react-icons/ai";
import { useHistory, useLocation } from "react-router-dom";
import validator from "validator";
const sha256 = require('crypto-js/sha256');
const hmacSHA512 = require('crypto-js/hmac-sha512');
const Base64 = require('crypto-js/enc-base64');

const SECONDS_TO_REDIRECT = 5;

export const PasswordReset = () => {

	const queryString = useLocation().search;
	// gets query string if you do /takeSurvey?page=aaaaaa&guestId=aaaaaa&eventId=12345
	const code = (new URLSearchParams(queryString).get('oobCode')) || "";

	const [showForm, setShowForm]									= useState(false)
	const [newPassword, setNewPassword]								= useState("")
	const [passError, setPassError]									= useState(false)
	const [newPasswordConfirm, setNewPasswordConfirm]				= useState("")
	const [passConfirmError, setPassConfirmError]					= useState(false)
	const [showPassowrdChangeSuccess, setShowPasswordChangeSuccess]	= useState(false)
    const history = useHistory();

	function displayResetPasswordTextBox(auth:any, actionCode:string, continueUrl:string, lang:string) {
		verifyPasswordResetCode(auth, actionCode).then((email) => {
			setShowForm(true)
		}).catch((error) => {
			// Invalid or expired action code. Ask user to try to reset the password
			// again.
			setShowForm(false)

		});
	}

	function validatePass (password: string) {
		setNewPassword(password)

		if(password === ''){
			setPassError(true)
			return false;
		}

		const valid = (validator.isWhitelisted(password.toLowerCase(), 'abcdefghijklmnopqrstuvwxyz0123456789!@#$%&* ') &&
                validator.isStrongPassword(password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 0, returnScore: false }));

		setPassError(!valid);
		return(valid)
	}

	function validateConfirmPassword (confirmPassword: string){
		setNewPasswordConfirm(confirmPassword)
		if (confirmPassword !== newPassword){
			setPassConfirmError(true)
			return false
		}
		else{
			setPassConfirmError(false)
			return true
		}
	}


	let handleSubmit = (event: any) => {
        event.preventDefault();
		if (passError || passConfirmError || !validatePass(newPassword) || !validateConfirmPassword(newPasswordConfirm) ){
			return
		}

		const message: string = newPassword.trim(); // ...
		const nonce: string = "CFd9zkLFmAN*q7tt4m#Vilhlh#32lZBfvAFaZkWyU4AxzsGZ$cMNfrBTjAF3uYF55LgriWOa22oM9LT&&7tdqzOvmy5IPwQZLsIQM9U00f";
		const path: string = "path";
		const privateKey: string = "epwEj#H0CzFWX3sRHIr4FV7UB8Cn8iBusWk5DIc9w2R5hj!!2jTy9LFEpBsKWcpX#pgjIgg3zJpYQCKOt@LInCuwo4Y9eXro&^jgl^hWus(pc";
		const hashDigest = sha256(nonce + message);
		const hashedPass = Base64.stringify(hmacSHA512(path + hashDigest, privateKey));

		confirmPasswordReset(auth, code, hashedPass).then((resp) => {
			// Password reset has been confirmed and new password updated.

			setShowForm(false);
			setShowPasswordChangeSuccess(true);
			const timer = setTimeout(() => {
				history.push('/userHome');
				clearTimeout(timer);
			}, SECONDS_TO_REDIRECT * 1000);
				// setShowPasswordChangeSuccess(true)
		}).catch((error) => {
			// Error occurred during confirmation. The code might have expired or the
			// password is too weak.
			setShowPasswordChangeSuccess(false)

		});
	}

	const auth = getAuth();

	displayResetPasswordTextBox(auth, code, "", "")


	return (
		<section>
            <h1 className="title">Reset Your Password</h1>
			{
				!showPassowrdChangeSuccess && showForm?
				<>
					<p className='subtitle'>Reset your password</p>
					<form onSubmit={handleSubmit} className='vertical-form'>
						<section className='formBox'>
							<TextField
								id='passInpLogin'
								variant='outlined'
								size='small'
								className='loginInputs'
								type="password"
								autoComplete='true'
								name='password'
								label='Password'
								value={newPassword}
								error={passError}
								helperText={passError ? <>At least 8 characters. Must contain: <br />1. One uppercase letter <br />2. One lowercase letter <br />3. One number <br />Can contain spaces and the following special symbols: [ ! @ # $ % & * ] </> : ''}
								onChange={(e) => validatePass(e.target.value)}
							/>
							<TextField
								variant='outlined'
								size='small'
								className='loginInputs'
								type="password"
								autoComplete='true'
								name='passwordConfirm'
								label='Confirm Password'
								value={newPasswordConfirm}
								error={passConfirmError}
								helperText={passConfirmError ? 'Passwords do not match.' : ''}
								onChange={(e) => validateConfirmPassword(e.target.value)}
							/>
						</section>
						<Button type='submit' className='basicBtn' variant='contained'>Change Password</Button>
					</form>
				</>
				: showPassowrdChangeSuccess && !showForm?
				<>
					<p className='subtitle'>Password successfully changed! Redirecting home in {SECONDS_TO_REDIRECT} seconds...</p>
					<Button onClick={() => history.push('/userHome')} className='basicBtn' variant='contained'>Return Home</Button>
				</>
				:
				<>
					<p className='subtitle'>This isn't a valid link. Please try again.</p>
					<Link className="forgotPasswordLink" onClick={() => {history.push('/requestPasswordReset')}}>Request Password</Link>
				</>
			}
		</section>
	)
}
