import './Profile.css'
import { Link, useHistory } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, IconButton, Switch } from "@mui/material";
import { AiFillPlusCircle } from "react-icons/ai";
import React, { useLayoutEffect } from "react";
import { HiTrash } from 'react-icons/hi';


export function UserAccount() {
    const history = useHistory();

    const toEditDetails = () => {
        history.push('/editAccount');
    };
    return (
        <>
                    <h1 className='title'>Hello{window.firstNameState ? (window.firstNameState.trim() === '' ? '!' : ', ' + window.firstNameState + '!') : '!'}</h1>
            <Card className='dashCard bigCard'>
                <CardHeader title='Your Details' className='dashCardHeader'/>
                <CardContent>
                    <p><strong>First Name: </strong> {window.firstNameState ? window.firstNameState.trim() : 'Unknown'} </p>
                    <p><strong>Last Name: </strong> {window.lastNameState ? window.lastNameState.trim() : 'Unknown'} </p>
                    <p><strong>Email: </strong> {window.emailState ? window.emailState.trim() : 'Unknown'} </p>
                </CardContent>
                <CardActions className='cardFooter'>
                    <Button onClick={toEditDetails} className='basicBtn fitBtn' variant='contained'>
                        Edit Details
                    </Button>
                </CardActions>
            </Card>
            

            
        </>
    );
}
