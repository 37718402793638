import { useHistory } from "react-router-dom";
import { Button } from '@mui/material';
import { useState, useLayoutEffect, useEffect } from 'react';
import { GuestListTable, GuestListDataInterface } from "../guestList/GuestListTable"
import { AddGuestPopUp } from "../guestList/AddGuestPopUp";
import './editGuestList.css';
import { UploadFile } from "../shared/UploadFile";
import Tooltip from '@mui/material/Tooltip';
import React from "react";


export function EditGuestList() {
	const [guestListData, setGuestListData] = useState<GuestListDataInterface[]>([])
	const [open, setOpen] = React.useState(false);
	const text = "Welcome to Placecard! You are currently experiencing a beta feature of our application, which means that we are still testing and developing to make Placecard the best it can be. If you experience any issues, please email help@placecard.com. Thank you!"

	useEffect(() => {
		if (!guestListData) {
			setGuestListData([]);
		}
	}, [guestListData]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	useLayoutEffect(() => {
		if (window.activeEvent && window.activeEvent.guestList){
			const startingGuests: GuestListDataInterface[] = [...guestListData];
			const startingGroups: any = {};
			if (window.activeEvent !== null) {
				// loop over guest list to fillout guestListData state
				for (let guest of window.activeEvent.guestList) {
					/* single people */
					if (guest.groupName == undefined || guest.groupName.trim() === "") {
						let currentDate = "N/A";
						if(guest.last_sent && guest.last_sent!="N/A") {
							let tempDate = guest.last_sent.split(" ");
							currentDate = tempDate[0] + ". " + tempDate[1] + " " + tempDate[2] + ", " + tempDate[3] ;
						}
						const newGuest: GuestListDataInterface = {
							individualName: guest.name,
							// groupName: guest.name,
							groupContact: guest.contact,
							groupSize: guest.groupSize?.toString(),
							sendSurvey: window?.activeEvent?.toSend?.includes(guest.id) || false,
							last_sent: currentDate,
							groupMembers: [],
							id: guest.id
						};
						if (!startingGuests.some(g => { if (g.id === newGuest.id) return true; })) {
							startingGuests.push(newGuest);
						}
					}
					/* groups */
					else if (guest.groupID != undefined && Object.keys(startingGroups).includes(guest.groupID)) {
						startingGroups[guest.groupID].groupMembers.push({ "guest_name": guest.name, "guest_id": guest.id });
					}
					else if (guest.groupID != undefined) {
						startingGroups[guest.groupID] = {
							groupName: guest.groupName,
							groupContact: guest.contact,
							groupSize: guest.groupSize?.toString(),
							sendSurvey: window?.activeEvent?.toSend?.includes(guest.id) || false,
							groupMembers: [{ "guest_name": guest.name, "guest_id": guest.id }],
							id: guest.groupID,
							group_id: guest.groupID
						}
					}
				}
				// loop over guest
				for (let groupID of Object.keys(startingGroups)) {
					const group = startingGroups[groupID];
					const newGroup: GuestListDataInterface = {
						groupName: group.groupName,
						groupContact: group.groupContact,
						groupSize: group.groupSize,
						sendSurvey: group.sendSurvey,
						last_sent: "N/A",
						groupMembers: group.groupMembers,
						// id: group.id
						group_id: group.id,
					};
					if (!startingGuests.some(g => { if (g.group_id === newGroup.group_id) return true; })) {
						startingGuests.push(newGroup);
					}
				}
			}
			setGuestListData(startingGuests);
		}
	}, [window.activeEvent])

	const history = useHistory();
	let handleClick = () => {
		history.push('/eventDash');
	}

	let surveySend = () => {
		history.push('/editSurvey');
	}

	return (
		<section className="editGuestListPage">
			<h1 className='title'>Edit Your Guest List</h1>
			<Tooltip open={open} onClose={handleClose} onOpen={handleOpen} title={text} placement="bottom">
				<h2 className="betaHeader">Beta Feature</h2>
			</Tooltip>
			<section className="pageNavigationButtons">
				<Button variant='contained' onClick={handleClick}>Return to Dashboard</Button>
				<Button variant='contained' onClick={surveySend}>{"Send Surveys & Reminders"}</Button>
			</section>
			<section className="manualGuestListSection">
				<GuestListTable tableData={guestListData} setTableData={setGuestListData} mode="SurveySent" />
				<section className="sendSurveyButton">
						<Button variant='contained' onClick={surveySend}>{"Send Surveys & Reminders"}</Button>
				</section>
				<AddGuestPopUp guestListData={guestListData} setGuestListData={setGuestListData} />
			</section>

			<section className="horizontalOr">
				<div className="horizontalBar" />
				<span>OR</span>
				<div className="horizontalBar" />
			</section>

			<UploadFile setTableData={setGuestListData} currTableState={guestListData}/>
		</section>
	)
}