import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import OAuthDialogList from "../firebase/OAuthDialogList";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import validator from "validator";
import { useHistory } from "react-router-dom";





export const RequestPasswordReset = () => {
    const[userEmail, setUserEmail] = useState("")
    const[emailError, setEmailError] = useState(false)
    const history = useHistory();



    const auth = getAuth();
    function resetPassword(email: string){
        sendPasswordResetEmail(auth, email)
            .then(() => {
                // Password reset email sent!
                // ..
                //redirect to succes page
                history.push('/emailPasswordResetSuccess')
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
                history.push('/emailPasswordResetFail')

                // ..
            });
    }

    function validateEmail(email:string): boolean{
        if(validator.isEmail(email)){
            return true
        }

        return false;
    }


    let handleSubmit = async (event: any) => {
        event.preventDefault();
        if (validateEmail(userEmail)){
            resetPassword(userEmail)
        }
        else{
            setEmailError(true)
        }
    }

    useEffect(() => {
        if (emailError){
            if (validateEmail(userEmail)){
                setEmailError(false)
            }
        }
    }, [userEmail])


    return(
        <section className="RequestPasswordReset">
            <h1 className="title">Forgot your Password?</h1>
            <p className='subtitle'>Let's get this figured out. We'll send a password reset link to your email.</p>
            <form className='vertical-form' id="requestPassowrdResetForm" onSubmit={handleSubmit}>
                <section className='formBox'>
                    <TextField
                        variant='outlined'
                        size='small'
                        type='email'
                        label='Email'
                        name='email'
                        placeholder="johnsmith@gmail.com"
                        value={userEmail}
                        error={emailError}
                        helperText={emailError ? "Please enter a valid email" : ""}
                        onChange={(e) => setUserEmail(e.target.value)}
                    />
                </section>

                <Button type='submit' className='basicBtn' variant='contained'>Submit</Button>

            </form>
        </section>
    )
}