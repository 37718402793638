import { useHistory } from "react-router-dom";
import Card from '@mui/material/Card';
import { Button, CardActions, CardContent, CardHeader } from "@mui/material";
import { PieChart } from 'react-minimal-pie-chart';


export function SeatCard(props: { tables: string; seats: string; invitees: string; seated: string }) {
    const history = useHistory();
    let percentSeated = 0;

    if (window.activeEvent != undefined  && window.activeEvent !== null) {
        let num_guests = window.activeEvent.guestList.length;
        let guestIDs: string[] = []
        
        if (window.activeEvent) {
            for (let t of window.activeEvent.tables) {
                let ids = t.guests.map( (guest) => {return guest.id})
                if (ids.length>0)
                guestIDs = guestIDs.concat(ids);
            }
        } 
        if (num_guests != 0){
            percentSeated = parseFloat(((guestIDs.length/num_guests)*100).toFixed(2));
        }
    }

    const toSeatDash = () => {
        history.push('/seatDash');
    };

    const percSeated = cleanPercentage(parseInt(props.seated) / parseInt(props.invitees));

    return (
        <Card className='dashCard bigCard'>
            <CardHeader title='Seating Chart' className='dashCardHeader' />
            <CardContent className='flexCard'>
                <section>
                    <p><strong>Tables:</strong> {props.tables}</p>
                    <p><strong>Seats:</strong> {props.seats}</p>
                    <p><strong>Invitees:</strong> {props.invitees}</p>
                    <p><strong>People Seated:</strong> {props.seated}</p>
                </section>
                {parseInt(props.invitees) > 0 && <section className='pie'><PieChart className='pieChart' totalValue={100} animate={true}
                    data={[
                        { title: 'Percent Seated: ' + percSeated.toString() + '%', value: percSeated, color: 'var(--highlight)' },
                        { title: 'Percent Not Seated: ' + (100 - percSeated).toString() + '%', value: 100 - percSeated, color: '#e5e5e5' }
                    ]}
                />
                    <p> {percentSeated}% of Guests Seated</p>
                </section>
                }

            </CardContent>
            <CardActions className='cardFooter'>
                <Button onClick={toSeatDash} className='basicBtn fitBtn' variant='contained'>
                    Edit Seating Chart
                </Button>
            </CardActions>
        </Card>
    );
}

function cleanPercentage(perc: number) {
    return Math.round(perc * 100);
}