import { createContext, useEffect, useState, useContext } from "react";
import { onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { getFirebaseAuth } from "../../utils/firebase/firebase"
import { useHistory } from "react-router-dom";

const FirebaseAuthContext = createContext(undefined);

function useAuthContext() {
    return useContext(FirebaseAuthContext);
}

const FirebaseAuthProvider = ({ children } : any) => {
    const firebaseAuth = getFirebaseAuth();
    const [firebaseUser, setFirebaseUser] = useState(undefined);

    const toggleUser = async (user: any) => {
        if (window.loggedInState==false) {
            window.setLoggedIn(!(user==null || user==undefined));
        }
        if (user !== null && user != undefined ) {
            window.setUID(user.uid);
            window.setFirstTime(Number(user.metadata.createdAt)+60 >= Number(user.metadata.lastLoginAt));
            window.setEmail(user.email);
            window.setLoggedIn(true);
            if (user.displayName != null) {
                // signed in with google, get info
                const name = user.displayName.split(' ');
                window.setFirstName(name[0]);
                window.setLastName(name[name.length-1]);
                window.setPhone(user.phoneNumber);
                window.setProfPic(user.photoURL);
                if (Number(user.metadata.createdAt)+60 >= Number(user.metadata.lastLoginAt)) {
                    // create user in db because it is their first time logging in!
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            firebase_id: user.uid,
                            first_name: name[0],
                            last_name: name[name.length-1],
                            email: user.email
                        })
                    };
                    await fetch(process.env.REACT_APP_BACKEND_URL + '/users/newUser', requestOptions);
                }
            }
            else {
                // get first name/last name from db if not first time logging in
                if ( !(Number(user.metadata.createdAt)+60 >= Number(user.metadata.lastLoginAt))) {
                    try {
                        const dbUser = await fetch(process.env.REACT_APP_BACKEND_URL + '/users/getFirebaseUser/'+user.uid);
                        const jsonRes = await dbUser.json();
                        window.setFirstName(jsonRes.first_name);
                        window.setLastName(jsonRes.last_name);
                    }
                    catch {
                        console.error('could not get data for user')
                    }
                }
            }
        }
        setFirebaseUser(user);
    };

    useEffect(() => {
        return onAuthStateChanged(firebaseAuth, toggleUser);
    }, []);

    return (
        <FirebaseAuthContext.Provider value={firebaseUser}>
            {children}
        </FirebaseAuthContext.Provider>
    );
};

const newAccount = async (email:string, pass:string) => {
    try {
        const result = await createUserWithEmailAndPassword(getFirebaseAuth(), email, pass);

        return result;
    }
    catch (e) {
        return false;
    }
}

const checkAccount = async (email: string, pass:string) => {
    try {
        const result = await signInWithEmailAndPassword(getFirebaseAuth(), email, pass);
        return result;
    }
    catch (e) {
        return false;
    }
}

export {
    useAuthContext,
    FirebaseAuthProvider,
    newAccount,
    checkAccount
};