import { useHistory } from "react-router-dom";
import Card from '@mui/material/Card';
import { Button, CardActions, CardContent, CardHeader, IconButton } from "@mui/material";
import { PieChart } from 'react-minimal-pie-chart';
import { AiFillCloseCircle } from "react-icons/ai";
import { FullSurvey } from "../../guestPages/FullSurvey";


export function EditCard(props: { numSent: string; numRec: string, guests: string }) {
    const history = useHistory();

    const toGuestListEdit = () => {
        history.push('/editGuestList');
    };
    const percRec = cleanPercentage(parseInt(props.numRec) / parseInt(props.numSent));

    const previewSurvey = () => {
        const el = document.getElementById('surveyPreview');
        if (el !== null) {
            el.style.display = 'block';
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    const dontClose = (event: any) => {
        event.stopPropagation();
    };

    const closePreview = () => {
        const el = document.getElementById('surveyPreview');
        if (el !== null) {
            el.style.display = 'none';
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }

    return (
        <>
            <section className='hiddenBoxes' id='surveyPreview' onClick={closePreview}>
                <Card className='surveyPreview' onClick={dontClose}>
                    <CardHeader className='innerBoxHeader' action={<IconButton onClick={closePreview}><AiFillCloseCircle className='closeBtn' /></IconButton>} title='Preview Survey' />
                    <CardContent>
                        <FullSurvey preview={true} hostView={true}></FullSurvey>
                    </CardContent>
                    <CardActions className='previewFooter'>
                        <Button color='info' variant='contained' className='basicBtn fitBtn' onClick={closePreview}>Close Preview</Button>
                    </CardActions>
                </Card>
            </section>
            <Card className='dashCard bigCard'>
                <CardHeader title='Guest List' className='dashCardHeader' />
                <CardContent className='flexCard'>
                    <section>
                        <p><strong>Surveys Sent:</strong> {props.numSent} </p>
                        <p><strong>Surveys Completed:</strong> {props.numRec}/{props.numSent} </p>
                        <p><strong>Total Guests:</strong> {props.guests} </p>
                    </section>
                    {
                        parseInt(props.numSent) > 0 && <section className='pie'><PieChart className='pieChart' totalValue={100} animate={true}
                            data={[
                                { title: 'Percent Completed: ' + percRec.toString() + '%', value: percRec, color: 'var(--highlight)' },
                                { title: 'Percent Incomplete: ' + (100-percRec).toString() + '%', value: 100 - percRec, color: '#e5e5e5' }
                            ]}
                        />
                            <p>Percent of Surveys Completed</p>
                        </section>
                    }

                </CardContent>
                <CardActions className='cardFooter'>
                    <Button onClick={toGuestListEdit} className='basicBtn fitBtn' variant='contained'>
                        Edit Guest List
                    </Button>
                    <Button className='basicBtn fitBtn prevBtn' variant='contained' onClick={previewSurvey}>Preview Survey</Button>
                </CardActions>
            </Card>
        </>
    );
}

function cleanPercentage(perc: number) {
    return Math.round(perc * 100);
}
