import { GuestListTable, GuestListDataInterface } from "./GuestListTable"
import { AddGuestPopUp, addGuestToGlobalEvent } from "./AddGuestPopUp";
import { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import './GuestList.css'
import { UploadFile } from "../shared/UploadFile";

export function GuestList() {
	window.setGuestMode(false);
	const TITLE = 'Add a Guest List For Your Event'
	const PAGE_DESCRIPTION = 'Download our guest list template, fill it out, and drop it back here'
	const TABLE_TITLE = 'Enter guest list manually'
	const TABLE_DESCRIPTION = 'Only one contact method needed for each guest'

	const history = useHistory();

	const [guestListData, setGuestListData] = useState<GuestListDataInterface[]>([]);


	useEffect(() => {
		if (!guestListData) {
			setGuestListData([]);
		}
	}, [guestListData]);

	/* initialize guest list data */
	useEffect(() => {
		const startingGuests: GuestListDataInterface[] = [...guestListData];
		const startingGroups: any = {};
		if (window.activeEvent !== null) {
			// loop over guest list to fillout guestListData state
			for (let guest of window.activeEvent.guestList) {
				/* single people */
				if (guest.groupName == undefined || guest.groupName.trim() === "") {
					let currentDate = "N/A";
					if(guest.last_sent) {
						let tempDate = guest.last_sent.split(" ");
						currentDate = tempDate[0] + ". " + tempDate[1] + " " + tempDate[2] + ", " + tempDate[3] ;
					}
					const newGuest: GuestListDataInterface = {
						individualName: guest.name,
						// groupName: guest.name,
						groupContact: guest.contact,
						groupSize: guest.groupSize?.toString(),
						sendSurvey: window?.activeEvent?.toSend?.includes(guest.id) || false,
						last_sent: currentDate,
						groupMembers: [],
						id: guest.id
					};
					if (!startingGuests.some(g => { if (g.id === newGuest.id) return true; })) {
						startingGuests.push(newGuest);
					}
				}
				/* groups */
				else if (guest.groupID != undefined && Object.keys(startingGroups).includes(guest.groupID)) {
					startingGroups[guest.groupID].groupMembers.push({ "guest_name": guest.name, "guest_id": guest.id });
				}
				else if (guest.groupID != undefined) {
					startingGroups[guest.groupID] = {
						groupName: guest.groupName,
						groupContact: guest.contact,
						groupSize: guest.groupSize?.toString(),
						sendSurvey: window?.activeEvent?.toSend?.includes(guest.id) || false,
						groupMembers: [{ "guest_name": guest.name, "guest_id": guest.id }],
						id: guest.groupID,
						group_id: guest.groupID
					}
				}
			}
			// loop over guest
			if (typeof (startingGroups) == 'object') {
				for (let groupID of Object.keys(startingGroups)) {
					const group = startingGroups[groupID];
					let currentDate = "N/A";
					if(group.last_sent) {
						let tempDate = group.last_sent.split(" ");
						currentDate = tempDate[0] + ". " + tempDate[1] + " " + tempDate[2] + ", " + tempDate[3] ;
					}
					const newGroup: GuestListDataInterface = {
						groupName: group.groupName,
						groupContact: group.groupContact,
						groupSize: group.groupSize,
						sendSurvey: group.sendSurvey,
						last_sent: currentDate,
						groupMembers: group.groupMembers,
						// id: group.id
						group_id: group.id,
					};
					if (!startingGuests.some(g => { if (g.group_id === newGroup.group_id) return true; })) {
						startingGuests.push(newGroup);
					}
				}
				setGuestListData(startingGuests);
			}
		}
	}, [window.activeEvent]);


	const toCustomizeSurvey = (event: any) => {
		event.preventDefault()
		history.push('/editSurvey');
	}


	return (
		<>
			<section>
				<h1 className='title'>{TITLE}</h1>
				<p className='subtitle'>{PAGE_DESCRIPTION}</p>
			</section>

			<UploadFile setTableData={setGuestListData} currTableState={guestListData}/>

			<section className="horizontalOr">
				<div className="horizontalBar" />
				<span>OR</span>
				<div className="horizontalBar" />
			</section>

			<form id="addGuestListForm" onSubmit={toCustomizeSurvey}>

				<section className="manualGuestListSection">
					<h1 className='title'>{TABLE_TITLE}</h1>
					<p className='subtitle'>{TABLE_DESCRIPTION}</p>
					<AddGuestPopUp guestListData={guestListData} setGuestListData={setGuestListData} />
					<GuestListTable tableData={guestListData} setTableData={setGuestListData} mode="New" />
				</section>
				<Button type="submit" color="primary" variant="contained" title="Don't worry, you can always go back and change this!">Complete Guest List</Button>
			</form>

		</>
	);
}
