import "./OAuthDialogList.css"
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, List, TextField, InputAdornment, Button, Link } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import placecardLogo from "../../assets/logo.png";
import CustomOAuthBox from "./CustomOAuthBox";
import { PROVIDERS, authBoxConfigs } from "../../constants/mappings/authBoxConfigs";
import { loginDescription } from "../../constants/messages";
import { nanoid as uuid } from "nanoid";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import React, { useEffect } from "react";
import validator from "validator";
import { useHistory } from "react-router-dom";
import { checkAccount } from "./AuthProvider";
const sha256 = require('crypto-js/sha256');
const hmacSHA512 = require('crypto-js/hmac-sha512');
const Base64 = require('crypto-js/enc-base64');

const PROVIDER_NAMES: string[] = Object.values(PROVIDERS);

export interface OAuthDialogListProps {
    open: boolean,
    onClose: () => void
};

let validPass = true;
let validEmail = true;
/* we don't want to show/hide errors before they press submit for the first time */
let firstTime = true;
let email = '';
let password = '';

const OAuthDialogList = (props: { open: any, onClose: any, title: String, setOpen:(arg0: boolean) => void }) => {
    const history = useHistory();
    const open: any = props.open;
    const onClose = () => {
        validPass = true;
        validEmail = true;
        props.onClose()
        return
    };

    // const authList = PROVIDER_NAMES.map((providerName: any) => {
    //     return <CustomOAuthBox key={uuid()} authBoxConfig={authBoxConfigs[providerName]} />
    // });
    const authList = [<CustomOAuthBox key={uuid()} authBoxConfig={authBoxConfigs[PROVIDERS.GOOGLE]} />];

    /* the following togglePassword and toggleConfirm functions make the eye appear with
    or without a slash and change the password from visible to invisible or vice versa */
    let toggleOnPassword = () => {
        togglePassword('passNoEyeLogin');
    };
    let toggleOffPassword = () => {
        togglePassword('passEyeLogin');
    }

    const [passError, setPassError] = React.useState(!validPass);
    const [emailError, setEmailError] = React.useState(!validEmail);

    let validateEmail = (event: any, val?: string) => {
        if (val !== undefined) {
            email = val;
        }
        else {
            email = validator.trim(event.target.value);
        }
        if (!firstTime || val != undefined) {
            const valid = validator.isEmail(email);
            validEmail = valid;
            setEmailError(!valid);
            if (valid && validPass) {
                const y = document.getElementById('loginPageError');
                if (y !== null) {
                    y.style.display = 'none';
                }
            } else {
                const y = document.getElementById('loginPageError');
                if (y !== null) {
                    y.textContent = 'Please fix the errors below.';
                    y.style.display = 'inline-block';
                }
            }
        }
    }

    let validatePass = (event: any, val?: string) => {
        if (val !== undefined) {
            password = val;
        }
        else {
            password = event.target.value;
        }
        if (!firstTime || val != undefined) {
            const valid = (validator.isWhitelisted(password.toLowerCase(), 'abcdefghijklmnopqrstuvwxyz0123456789!@#$%&* ') &&
                validator.isStrongPassword(password, { minLength: 8, minLowercase: 0, minUppercase: 0, minNumbers: 0, minSymbols: 0, returnScore: false }));
            validPass = valid;
            setPassError(!valid);
            if (valid && validEmail) {
                const y = document.getElementById('loginPageError');
                if (y !== null) {
                    y.style.display = 'none';
                }
            } else {
                const y = document.getElementById('loginPageError');
                if (y !== null) {
                    y.textContent = 'Please fix the errors below.';
                    y.style.display = 'inline-block';
                }
            }
        }
    }

    useEffect(() => {
        /* Logged in using google, go to next page */
        if (window.loggedInState) {
            // check if there are any events the user has that have not passed
            let futureEvents = 0;
            for (let x of window.eventsState) {
                const daysLeft = Math.ceil(((new Date(x.date)).valueOf() - (new Date()).valueOf()) / 100000000);
                if (daysLeft > 0) {
                    futureEvents += 1;
                }
            }
            if (window.firstTime == undefined){
                window.firstTime = false;
            }
            return;
        }
    }, [window.eventsState, window.loggedInState]);


    useEffect(() => {
            setPassError(!validPass);
            setEmailError(!validEmail);
            return;
        }
    , [validEmail, validPass]);

    /* takes care of validation and sending data to the database when the "Login" button is pressed */
    let handleSubmit = async (event: any) => {
        event.preventDefault();
        const y = document.getElementById('loginPageError');

        /* get data from form and fill variables */
        email = validator.trim(event?.target?.email?.value);
        password = event?.target?.password?.value;

        /* begin validation process */
        validateEmail(null, email);
        validatePass(null, password);
        let errorFound = !(validEmail && validPass);
        if (!errorFound) {
            /* if form is good, create the new account */
            try {
                const message: string = password.trim();
                const nonce: string = "CFd9zkLFmAN*q7tt4m#Vilhlh#32lZBfvAFaZkWyU4AxzsGZ$cMNfrBTjAF3uYF55LgriWOa22oM9LT&&7tdqzOvmy5IPwQZLsIQM9U00f";
                const path: string = "path";
                const privateKey: string = "epwEj#H0CzFWX3sRHIr4FV7UB8Cn8iBusWk5DIc9w2R5hj!!2jTy9LFEpBsKWcpX#pgjIgg3zJpYQCKOt@LInCuwo4Y9eXro&^jgl^hWus(pc";
                const hashDigest = sha256(nonce + message);
                const hashedPass = Base64.stringify(hmacSHA512(path + hashDigest, privateKey));
                const result = await checkAccount(email.toLowerCase().trim(), hashedPass);
                if (result == false) {
                    if (y !== null) {
                        y.innerHTML = 'Incorrect email/password combination.<br>Please try again.';
                        y.style.display = 'inline-block';
                    }
                    return;
                }
                /* if logging in is successful, go to next page after setting window variables */
                window.setEmail(email);
                // log in with this data
                window.setLoggedIn(true);
                // check if there are any events the user has that have not passed
                let futureEvents = 0;
                for (let x of window.eventsState) {
                    const daysLeft = Math.ceil(((new Date(x.date)).valueOf() - (new Date()).valueOf()) / 100000000);
                    if (daysLeft > 0) {
                        futureEvents += 1;
                    }
                }
                history.push('/userHome');
            }
            catch {
                if (y !== null) {
                    y.textContent = 'Something went wrong. Please try again.';
                    y.style.display = 'inline-block';
                }
            }
        }
        /* if there is an error, tell the user */
        else {
            if (y !== null) {
                y.textContent = 'Please fix the errors below.';
                y.style.display = 'inline-block';
            }
            firstTime = false;
        }
    }

    return (
        <Dialog onClose={onClose} open={open}>
            <div className="closeButton">
                <Button className="close" onClick={onClose}>
                    <CloseIcon />
                </Button>
            </div>
            <DialogContent className="loginHeader">
                <Box
                    className="logoImage"
                    component="img"
                    alt="Placecard logo"
                    src={placecardLogo}
                />
                <DialogContentText className="loginDialogText">
                    {loginDescription}
                </DialogContentText>
            </DialogContent>
            <List>
                <li className='verticalFlex'>
                    <p className='subtitle pageError' id='loginPageError'>Something went wrong. Please try again.</p>
                    <form onSubmit={handleSubmit} className='verticalFlex'>
                        <TextField
                            variant='outlined'
                            className='loginInputs'
                            size='small'
                            type="text"
                            label='Email'
                            name='email'
                            defaultValue={window.loggedInState ? window.emailState : ''}
                            disabled={window.loggedInState}
                            error={emailError}
                            helperText={emailError ? 'Please enter a valid email' : ''}
                            onChange={validateEmail}
                        />
                        <TextField
                            id='passInpLogin'
                            variant='outlined'
                            size='small'
                            className='loginInputs'
                            type="password"
                            autoComplete='true'
                            name='password'
                            label='Password'
                            error={passError}
                            helperText={passError ? 'Password must be at least 8 characters.' : ''}
                            onChange={validatePass}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <AiFillEyeInvisible className='passEye' id='passNoEyeLogin' onClick={toggleOnPassword} />
                                        <AiFillEye className='passEye yesEye' id='passEyeLogin' onClick={toggleOffPassword} />
                                    </InputAdornment>
                            }} />
                        {/* <a className='smallLink'>Forgot your password? Click here to reset.</a> */}
                        <Button type="submit" className='basicBtn loginInputs' variant='contained'>Login</Button>
                    </form>
                </li>

                <li>
                <Link className="forgotPasswordLink" onClick={() => {props.setOpen(false); history.push('/requestPasswordReset')}}>Forgot Password?</Link>
                </li>

                {/* <li className='specialAuthenticators'>
                    <section className="horizontalOr smallSpacing">
                        <div className="horizontalBar" />
                        <span>OR</span>
                        <div className="horizontalBar" />
                    </section>
                    <List>
                        {authList}
                    </List>
                </li> */}

            </List>
        </Dialog>
    );
};

/*
 * Calls toggleHelper with the correct information based on the id parameter
 * in order to hide/show the password to the user.
 */
function togglePassword(id: string) {
    let e = document.getElementById(id);
    if (e) {
        e.style.display = 'none';
    }
    if (id === 'passNoEyeLogin') {
        toggleHelper('passEyeLogin', 'passInpLogin', e, 'text');
    }
    else if (id === 'passEyeLogin') {
        toggleHelper('passNoEyeLogin', 'passInpLogin', e, 'password');
    }
}

/*
 * Takes the necessary steps to hide/show the password to the user.
 * iconID is the HTML id of the icon that needs to be shown
 * inputID is the HTML id of the input box that needs its type to be changed
 * backupElem is the element that has been hidden but, if something goes wrong, will need to be shown again
 * type is the type that the input needs to change to. Should either be 'text' or 'password'
 */
function toggleHelper(iconID: string, inputID: string, backupElem: HTMLElement | null, type: string) {
    let x = document.getElementById(iconID);
    /* show the correct icon */
    if (x) {
        x.style.display = 'inline-block';
        /* change the type of the input */
        let y = document.getElementById(inputID);
        if (y) {
            y.setAttribute('type', type);
        }
    }
    /* if something went wrong, show the old element */
    else {
        if (backupElem) {
            backupElem.style.display = 'inline-block';
        }
    }
}

export default OAuthDialogList;