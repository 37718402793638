import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

export const EmailPasswordResetSuccess = () => {
    const history = useHistory();

    return(
        <>
            <h1 className="title">Sent!</h1>
            <p className="subtitle">Password reset email was sent</p>
            <p>Check your email for a link to reset your password. If you don't see it in your email, check your spam folder.</p>
            <Button onClick={() => history.push('/')} className='basicBtn' variant='contained'>Home</Button>
        </>
    )
}