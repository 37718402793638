import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

export const EmailPasswordResetFail = () => {
    const history = useHistory();

    return(
        <>
            <h1 className="title">Email Not Sent</h1>
            <p>Password reset email was not sent. Please try again.</p>
            <Button onClick={() => history.push('/requestPasswordReset')} className='basicBtn' variant='contained'>Go Back</Button>
        </>
    )
}