import { useHistory, useLocation  } from 'react-router-dom';
import { FaFacebookSquare, FaInstagram, FaTwitterSquare } from 'react-icons/fa';
import { Button, ButtonGroup, Box, Link } from "@mui/material";
import logo from '../../assets/whiteLogo.png';
import './HeadFoot.css';

export function Footer() {
    const history = useHistory();
    const { pathname } = useLocation();
    if (pathname === "/seatDash") return null;

    const viewPrivacyPolicy = () => {
        history.push('./privacyPolicy');
    }

    const viewTermsAndConditions = () => {
        history.push('./termsAndConditions');
    }

    return (
    <footer className='footer'>
        <img className='logo' src={logo} alt='Logo'></img>


        <Box>
            <Link className='clickable'
                onClick={viewPrivacyPolicy}
            >
                Privacy Policy
            </Link>
             |
            <Link className='clickable'
                onClick={viewTermsAndConditions}
            >
                Terms and Conditions
            </Link>
        </Box>

        <section className='footerInfo'>
            Questions? Contact us at
            <a href="mailto:info@placecard.com" className='contactInfo'>
                info@placecard.com</a>
        </section>


        {/* <p className='contactInfo'>(123) 456-7890</p> */}
        {/* <section className='socialIcons'>
            <FaFacebookSquare></FaFacebookSquare>
            <FaTwitterSquare></FaTwitterSquare>
            <FaInstagram></FaInstagram>
        </section> */}
        <p className='copyright'>&#169; 2024 Placecard Inc.</p>
    </footer>
    );
}
