import { useRef, useState } from "react";
import { MdUploadFile } from 'react-icons/md';
import '../guestList/GuestList.css';
import template from '../../assets/Placecard_Guestlist_Template.png';
import { AiOutlineFileExcel } from "react-icons/ai";
import { addGuestToGlobalEvent } from "../guestList/AddGuestPopUp";
import { GuestListDataInterface } from "../guestList/GuestListTable"

export function UploadFile(props: {setTableData:Function, currTableState:GuestListDataInterface[]}) {
	const [userFile, setUserFile] = useState(undefined);
    const [uploadError, setUploadError] = useState(false);
    let eventId = '';
    const fileSelected = (event: any) => {
        setUploadError(false);
		const selectedFile = event.target.files[0];
		setUserFile(selectedFile);
		let data = new FormData();
        data.append('file', selectedFile);
        if (window && window.activeEvent){
            eventId =  window.activeEvent.id;
        }

		const requestOptions = {
			method: 'POST',
            body: data
		};
        fetch(process.env.REACT_APP_BACKEND_URL + '/guests/fileUpload/'+eventId, requestOptions)
            .then(response => response.json())
            .then(data => {
                //delete all the old data from the database bc we are going to override it with new data
		        const requestOptions = {method: 'DELETE',}
                props.currTableState.forEach(tableRowData => {
                    if(tableRowData.groupMembers.length > 1){
                        tableRowData.groupMembers.forEach(group_member => fetch(process.env.REACT_APP_BACKEND_URL + '/guests/' + group_member.guest_id, requestOptions))
                    }
                    else{
                        fetch(process.env.REACT_APP_BACKEND_URL + '/' + tableRowData.id, requestOptions)
                    }
                });
                handleUploadedData(data);
            })
	}

    const handleUploadedData = (data: any) => {
        //overrride existing table
        if (data.returnData) {
            props.setTableData(data.returnData);
        } else {
            setUploadError(true);
            setUserFile(undefined);
            return;
        }
        if (window.activeEvent) {
            window.activeEvent.guestList = [];
            window.activeEvent.toSend = [];
            window.setInvitees([]);
            window.activeEvent.tables = [];
        }
        data.addedGuests.map((guest: any) => {addGuestToGlobalEvent(guest);})
    }




    return (
        <section className='fileUploadSection'>
        <a href={template} className='downloadTemplate' download="Placecard_Guestlist_Template.xlsx">
                <AiOutlineFileExcel size={30}/>
                <span>Download our Template!</span>
        </a>
        {uploadError && <p className="errorText">There was an error uploading your file. Please make sure emails are in the correct format and that you have uploaded a csv or xls(x) file and try again.</p>}
        <section className="fileUploadButtonSection">
            <MdUploadFile className="uploadFileIcon"/>
            <section>
                <p>Drag your file here or click here to upload</p>
                <label>{userFile != undefined  ? userFile['name'] : "No File Selected"}</label>
            </section>
        </section>

        <section id="dragDropContainer">
            <input
                id = "inputTestTag"
                type="file"
                ref={useRef(null)}
                title=""
                value=""
                onChange={fileSelected}
                accept=".xls,.xlsx,.csv"
            />
        </section>
    </section>
    );

}