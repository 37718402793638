import '../shared/HeadFoot.css';
import { useState, MouseEvent, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Avatar, Button, Menu, MenuItem } from '@mui/material';
import { userSignOut } from "../../utils/firebase/firebaseAuth";
import "../shared/HeadFoot.css";
import React from 'react';

// function ProfileMenu(props: any) {
//     const [ anchorEl, setAnchorEl ] = useState(undefined);
//     const [open, setOpen ] = useState(false);

//     const handleMenuClick = (event: any) => {
//         setAnchorEl(event.currentTarget);
//         setOpen(true);
//     };

//     const handleMenuClose = (event: any) => {
//         setAnchorEl(undefined);
//         setOpen(false);
//     };

//     return (
//         <div className="profileMenu">
//             <Button className="profileAvatarButton" variant="text" size="small" onClick={handleMenuClick}>
//                 <Avatar alt={props.user.displayName} src={props.user.photoURL}/>
//             </Button>
//             <Menu
//                 className="profileMenuDropdown"
//                 anchorEl={anchorEl}
//                 open={open}
//                 onClose={handleMenuClose}
//             >
//                 <MenuItem divider>Signed in as: {props.user.displayName}</MenuItem>
//                 <MenuItem onClick={userSignOut}>Sign out</MenuItem>
//             </Menu>
//         </div>

//     )
// }

// export default ProfileMenu;

export function ProfileMenu(props: {user: any}) {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const validEmails = ['attiq.amjad@placecard.com', 'kaitlyn.sharo@placecard.com', 'caleb.choy@placecard.com', 'alex.rubino@placecard.com', 'kaiqi.chee@placecard.com', 'gil.austria@placecard.com'];
    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };


    const names = props.user.displayName != null ? props.user.displayName.split(' ') : [];
    let initInitials = '';
    if (names.length > 1 && names[0].length >=1 && names[names.length-1].length >=1) {
        initInitials = names[0][0].toUpperCase() + names[names.length-1][0].toUpperCase();
    }
    else if (names.length == 1 && names[0].length >= 1) {
        initInitials = names[0][0];
    }
    else {
        if (window.firstNameState && window.lastNameState) {
            initInitials = window.firstNameState[0] + window.lastNameState[0];
        }
        else {
            initInitials = '--';
        }
    }

    const [initials, setInitials] = React.useState(initInitials);
    const [fullName, setFullName] = React.useState(props.user.displayName);

    useEffect( () => {
        const userNames = [window.firstNameState, window.lastNameState];
        if (userNames.length > 1 && userNames[0].length >=1 && userNames[userNames.length-1].length >=1) {
            setInitials(userNames[0][0].toUpperCase() + userNames[userNames.length-1][0].toUpperCase());
        }
        else if (userNames.length == 1 && userNames[0].length >= 1) {
            setInitials(userNames[0][0]);
        }
        else {
            if (window.firstNameState && window.lastNameState) {
                setInitials(window.firstNameState[0] + window.lastNameState[0]);
            }
            else {
                setInitials('--');
            }
        }
        setFullName(window.firstNameState + ' ' + window.lastNameState);
    }, [window.firstNameState, window.lastNameState]);

    const goEditProfile = () => {
        history.push('/editProfile');
    };

    const userHome = () => {
        history.push('/userHome');
    };

    const devTools = () => {
        history.push('/devRoutes');
    };
    const userDash = () => {
        history.push('/userDash');
    };

    const logOut = () => {
        userSignOut();
        window.setLoggedIn(false);
        window.setFirstTime(undefined);
        history.push('/');
    };

    const initCode = <Avatar className='profilePic' onClick={ handleClick } sx={{ width: 46, height: 46 }}>{initials}</Avatar>;
    const imgCode = <Avatar className='profilePic' alt={initials} onClick={ handleClick } src={window.profPicState == null  ? props.user.photoURL : window.profPicState} sx={{ width: 46, height: 46 }}>{initials}</Avatar>;
    return (
        <>
            {window.profPicState == null  && props.user.photoURL == null  ? initCode : imgCode}
            <Menu anchorEl={ anchorEl }
                open={ open }
                onClose ={ handleClose }
                onClick={ handleClose }
                autoFocus={false}>
                {/* <MenuItem id='fullName' style={{ pointerEvents: 'none' }}>{fullName}</MenuItem> */}
                <MenuItem onClick={ userDash } id='fullName' >{fullName}</MenuItem>
                {/* <MenuItem onClick={ userHome }>Home</MenuItem> */}
                {validEmails.includes(window.emailState.toLowerCase())  && <MenuItem onClick={devTools}>User Analytics</MenuItem>}
                {/* <MenuItem id='fullName' style={{ pointerEvents: 'none' }}>{fullName}</MenuItem> */}
                <MenuItem onClick={ userHome }>Events</MenuItem>
                {/* <MenuItem onClick={ goEditProfile }>View/Edit Profile</MenuItem> */}
                <MenuItem onClick={ logOut }>Logout</MenuItem>
            </Menu>
        </>
    );
}