import { useHistory } from "react-router-dom";
import logo from '../../assets/logo.png';
import './HeadFoot.css';
import ProfileBox from "../profile/ProfileBox";
import React from "react";

export function Header() {
  const history = useHistory();

  // React.useEffect(() => {
  //   if ((window.uidState === '' || window.uidState === undefined || window.uidState === null)) {
  //     history.push('/');
  //   }
  // }, []);

  const handleClick =  () => {
    history.push('/');
  }

  return (
    <header className= {`header stickTop`}>
        <img className='logo betaLogo' id='betaLogo' onClick={handleClick} src={logo} alt='Logo'></img>
      <ProfileBox/>
    </header>
  );
}